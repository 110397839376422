import React, { Fragment } from "react";

import SwiperCore, { Pagination } from "swiper";

import Heading from "shared/components/Heading/Heading";

import Paragraph from "shared/components/Paragraphs/Paragraph";
import Image from "shared/components/Image/Image";
import LogosSection from "shared/components/LogosSection/LogosSection";

import betfairYellowLogoNoBg from "shared/assets/brand-logo.svg";
import betfairYellowLogoNoBgDimensions from "shared/assets/brand-logo-2.svg";

import bfYellowBlackBg from "shared/assets/Logos_correct_incorrect/Correct/bf-yellow-black-bg.jpg";
import bfBlackYellowBg from "shared/assets/Logos_correct_incorrect/Correct/bf-black-yellow-bg.jpg";
import bfYellowBluekBg from "shared/assets/Logos_correct_incorrect/Correct/bf-yellow-blue-bg.jpg";
import bfBlackBluekBg from "shared/assets/Logos_correct_incorrect/Correct/bf-black-blue-bg.jpg";

import bgBlackYellowBgNoarrows from "shared/assets/Logos_correct_incorrect/Incorrect/bf-black-yellow-bg-noarrows.jpg";
import bfChangedFontstyle from "shared/assets/Logos_correct_incorrect/Incorrect/bf-changed-fontstyle.jpg";
import bfResized from "shared/assets/Logos_correct_incorrect/Incorrect/bf-resized.jpg";
import bfWhiteArrowsWhitebf from "shared/assets/Logos_correct_incorrect/Incorrect/bf-white-arrows-whitebf.jpg";
import bfWhiteArrows from "shared/assets/Logos_correct_incorrect/Incorrect/bf-white-arrows.jpg";
import bfWhiteYellowbg from "shared/assets/Logos_correct_incorrect/Incorrect/bf-white-yellow-bg.jpg";
import bfYellowBlackBgNoarrows from "shared/assets/Logos_correct_incorrect/Incorrect/bf-yellow-black-bg-noarrows.jpg";
import bfBlackYellowIncorrect from "shared/assets/Logos_correct_incorrect/Incorrect/bg-black-yellow-incorrect.jpg";
import LogoSectionItem from "shared/components/LogoSectionItem/LogoSectionItem";

SwiperCore.use([Pagination]);
const BrandMarkTemplate = () => {
  return (
    <Fragment>
      <Heading text="Clearing space" />

      <Paragraph>
        <p>
          The Betfair brand logo is the device we use everywhere, and the
          primary tool in the brand's arsenal. So it's important that it's given
          the space it deserves to breathe. Remember to give it at least the
          clearance of one Betfair arrow around the perimeter.
        </p>
      </Paragraph>

      <LogosSection grid="twoCol">
        <Image
          src={betfairYellowLogoNoBg}
          alt="Yellow Betfair logo with no background"
        />
        <Image
          src={betfairYellowLogoNoBgDimensions}
          alt="Yellow Betfair logo with no background and dimensions"
        />
      </LogosSection>

      <Heading text="Correct use" icon="correct" />

      <Paragraph>
        <p>
          It's important that the Betfair logo stands out against the background
          on which it's placed for legibility. Give consideration to the
          contrast between the logo colour and the background.
        </p>
      </Paragraph>

      <LogosSection grid="fourCol">
        <LogoSectionItem
          img={bfYellowBlackBg}
          imgAlt="Yellow Betfair logo with black background"
          text="Betfair Yellow on Betfair Black"
        />
        <LogoSectionItem
          img={bfBlackYellowBg}
          imgAlt="Black Betfair logo with yellow background"
          text="Betfair Black on Betfair Yellow"
        />
        <LogoSectionItem
          img={bfBlackBluekBg}
          imgAlt="Black Betfair logo with blue background"
          text="Betfair Black on a light image background"
        />
        <LogoSectionItem
          img={bfYellowBluekBg}
          imgAlt="Yellow Betfair logo with blue background"
          text="Betfair Yellow on an image background"
        />
      </LogosSection>

      <Heading text="Incorrect use" icon="incorrect" />

      <Paragraph>
        <p>Tempting as it may be, do not change elements of the logo.</p>
      </Paragraph>

      <LogosSection grid="threeCol">
        <LogoSectionItem
          img={bfWhiteYellowbg}
          imgAlt="White Betfair on Yellow Background"
          text="White Betfair on Yellow Background"
          red
        />
        <LogoSectionItem
          img={bfBlackYellowIncorrect}
          imgAlt="Yellow betfair on black background with bigger arrows"
          text="Do not use bigger arrows"
          red
        />
        <LogoSectionItem
          img={bfYellowBlackBgNoarrows}
          imgAlt="Yellow betfair on black background no arrows"
          text="Missing arrows"
          red
        />
        <LogoSectionItem
          img={bfWhiteArrows}
          imgAlt="Yellow Betfair with white arrows"
          text="Do not use white arrows"
          red
        />
        <LogoSectionItem
          img={bgBlackYellowBgNoarrows}
          imgAlt="Black Betfair logo with yellow background and drop shadows for arrows"
          text="Do not use drop shadows or other effects on the brand mark"
          red
        />
        <LogoSectionItem
          img={bfWhiteArrowsWhitebf}
          imgAlt="White Betfair with image background"
          text="Do not place the logo on a busy section of an image"
          red
        />
        <LogoSectionItem
          img={bfChangedFontstyle}
          imgAlt="Yellow Betfair logo with different font style"
          text="Do not change the font style"
          red
        />
        <LogoSectionItem
          img={bfResized}
          imgAlt="Yellow Betfair logo with black background"
          text="Do not distort the logo"
          red
        />
      </LogosSection>
    </Fragment>
  );
};

export default BrandMarkTemplate;
