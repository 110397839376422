export const addClass = (...args) => {
  const unique = {};
  args.forEach((str) => {
    if (!str || !str.trim()) {
      return;
    }
    unique[String(str).trim()] = 1;
  });

  return Object.keys(unique).join(" ") || "";
};
export const makeClass = (...args) => args.join("--");
