export const downloadsData = [
  {
    name: "Logos",
    files: [
      {
        url: "./downloads/1_Logos for Microsite/BF_LOGO.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/Betfair Logo on Yellow Box.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/BF_LOGO_H_Black.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/BF_LOGO_H_CMYK_Carbon_on Yellow.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/BF_LOGO_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/BF_LOGO_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/BF_LOGO_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/1_Betfair MASTER Logo/BF_LOGO_H_Yellow.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Carbon+White brand line RGB.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Carbon+White brand line.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Carbon+White brand line.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Carbon+Yellow brand line RGB.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Carbon+Yellow brand line.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Carbon+Yellow brand line.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_White+Carbon brand line RGB.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_White+Carbon brand line.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_White+Carbon brand line.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_White+Yellow brand line RGB.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_White+Yellow brand line.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Yellow+Carbon brand line.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Yellow+White brand line RGB.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Yellow+White brand line.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/2 Because We're Betfair Logo/Because We're Betfair_H_Yellow+White brand line.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/Betfair Bingo_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/Betfair Bingo_H_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/Betfair Bingo_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/Betfair Bingo_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/BF_LOGO_BINGO_V_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/BF_LOGO_BINGO_V_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/BF_LOGO_BINGO_V_CMYK_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Bingo/BF_LOGO_BINGO_V_CMYK_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Casino/Betfair Casino_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Casino/Betfair Casino_H_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Casino/Betfair Casino_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Casino/Betfair Casino_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Casino/BF_LOGO_CASINO_V_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Casino/BF_LOGO_CASINO_V_CMYK_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange/BF_EX_LOGO_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange/BF_EX_LOGO_H_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange/BF_EX_LOGO_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange/BF_EX_LOGO_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange/BF_EX_LOGO_V_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange/BF_EX_LOGO_V_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/Betfair Exchange Games_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/Betfair Exchange Games_H_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/Betfair Exchange Games_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/Betfair Exchange Games_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/BF_EXG_CARBON_V_CMYK.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/BF_EXG_CARBON_V_CMYK.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/BF_EXG_WHITE_V_CMYK.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Exchange Games/BF_EXG_WHITE_V_CMYK.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_H_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_V_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_V_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_V_CMYK_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Live Casino/Betfair Live Casino_V_CMYK_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/Betfair Poker_H_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/Betfair Poker_H_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/Betfair Poker_H_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/Betfair Poker_H_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/BF_LOGO_POKER_V_CMYK_Carbon.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/BF_LOGO_POKER_V_CMYK_Carbon.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/BF_LOGO_POKER_V_CMYK_White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betfair Poker/BF_LOGO_POKER_V_CMYK_White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betting.Betfair Logos/BLOG_ENG_BLACK.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betting.Betfair Logos/BLOG_ENG_Black.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betting.Betfair Logos/BLOG_ENG_plain.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betting.Betfair Logos/BLOG_ENG_plain.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betting.Betfair Logos/BLOG_ENG_YELLOW.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/3 Sub Brands/Betting.Betfair Logos/BLOG_ENG_YELLOW.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Black/Bet-Builder-Black.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Black/Bet-Builder-Black.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Black/Bet-Builder-Black.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Primary/Bet-Builder.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Primary/Bet-Builder.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Primary/Bet-Builder.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/White/Bet-Builder-White.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/White/Bet-Builder-White.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/White/Bet-Builder-White.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Yellow/Bet-Builder-Yellow.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Yellow/Bet-Builder-Yellow.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/Betfair Bet Builder/Yellow/Bet-Builder-Yellow.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost CarbonBlack CMYK.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost Yellow CMYK.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost_Black.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost_Black.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost_Black.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost_Yellow.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost_Yellow.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/My OddsBoost Logo/MyOddsBoost_Yellow.png",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/OddsBoost Logo/ODDS-BOOST_logo-icon.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/OddsBoost Logo/ODDS-BOOST_logo-stacked.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/OddsBoost Logo/ODDS-BOOST_logo.ai",
      },
      {
        url: "./downloads/1_Logos for Microsite/4 Product Logos/OddsBoost Logo/ODDS-BOOST_logo.eps",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Bingo_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Casino_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Exchange Games_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Exchange_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Live Casino_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Poker_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Betfair Family/Betfair Virtual Sports_V_Carbon.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Product/ACCA-Insurance.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Product/Bet-Builder.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Product/Betfair-Boost.svg",
      },
      {
        url: "./downloads/1_Logos for Microsite/Logos_Product/My-Betfair-Boost.svg",
      },
    ],
  },
  {
    name: "Brand marks",
    files: [
      {
        url: "./downloads/2_Brand Marks for Microsite/BF_BRAND_MARK.svg",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/black/Black_Gradients.ai",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/black/Black_Gradients.eps",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/yellow/Betfair_Gold_Gradients_DOWN –  S.png",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/yellow/Betfair_Gold_Gradients_UP – N.png",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/yellow/Betfair_Gold_Gradients.ai",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/yellow/Betfair_Gold_Gradients.eps",
      },
      {
        url: "./downloads/2_Brand Marks for Microsite/BF_LOGO_H_Black_Icon.png",
      },
    ],
  },
  {
    name: "Colours",
    files: [
      {
        url: "./downloads/3_Colours for Microsite/Betfair_Bingo_Colours.zip",
      },
      {
        url: "./downloads/3_Colours for Microsite/Betfair_Casino_Colours.zip",
      },
      {
        url: "./downloads/3_Colours for Microsite/Betfair_Core_Colours.zip",
      },
      {
        url: "./downloads/3_Colours for Microsite/Betfair_Poker_Colours.zip",
      },
    ],
  },
  {
    name: "Font",
    files: [
      {
        url: "./downloads/4_Typeface for Microsite/NotoSans.zip",
      },
    ],
  },
  {
    name: "Imagery",
    files: [
      {
        url: "./downloads/5_Imagery/racing-imagery/RP_ID00343458.jpg",
      },
      {
        url: "./downloads/5_Imagery/racing-imagery/RP_ID00367890.jpg",
      },
      {
        url: "./downloads/5_Imagery/racing-imagery/RP_ID00373291.jpg",
      },
      {
        url: "./downloads/5_Imagery/racing-imagery/RP_ID00383081.jpg",
      },
      {
        url: "./downloads/5_Imagery/sports-imagery/Allianz Arena.jpg",
      },
      {
        url: "./downloads/5_Imagery/sports-imagery/Borussia_Dortmund_CORRECT.jpg",
      },
      {
        url: "./downloads/5_Imagery/sports-imagery/Casemiro_UKI_300dpi.png",
      },
      {
        url: "./downloads/5_Imagery/sports-imagery/Cheillini_UKI_222dpi.png",
      },
      {
        url: "./downloads/5_Imagery/sports-imagery/England---Wembley-New-VI.jpg",
      },
      {
        url: "./downloads/5_Imagery/sports-imagery/Kane_UKI_300dpi.png",
      },
      {
        url: "./downloads/5_Imagery/casino-imagery/Betfair_Casino_2022_PrizePinballJackpot_FullLength.png",
      },
      {
        url: "./downloads/5_Imagery/casino-imagery/Betfair68338.png",
      },
      {
        url: "./downloads/5_Imagery/casino-imagery/Picture-1.png",
      },
      {
        url: "./downloads/5_Imagery/casino-imagery/PLB5064_Betfair_Casino_2022_Stall_KV_01_Betfair67302_RET_FINAL.jpg",
      },
    ],
  },
  {
    name: "Playbooks",
    files: [
      {
        url: "./downloads/8_Playbooks for Microsite/Brand Identity Guidelines.jpg",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/DailyRewards_800x250.jpg",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/Global_800x250.jpg",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/Betfair Casino Campaign Guidelines - 2023 Final.pdf",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/BF Daily Rewards - Guidelines - 02.pdf",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/BF-VI-Toolkit_September2020_2.0.pdf",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/Betfair Branded PPT- 2020.potx",
      },
      {
        url: "./downloads/8_Playbooks for Microsite/Betfair Prize Pinball VI Guidelines - July 2023.pdf",
      },
    ],
  },
  {
    name: "Safer gambling",
    files: [
      {
        url: "./downloads/10_Safer_Gambling/SG Assets.zip",
      },
      {
        url: "./downloads/10_Safer_Gambling/UK_Responsible_Gambling.zip",
      },
      {
        url: "./downloads/10_Safer_Gambling/Ireland_Responsible_Gambling.zip",
      },
      {
        url: "./downloads/10_Safer_Gambling/gambling-icons/budget_calculator_icon-01.png",
      },
      {
        url: "./downloads/10_Safer_Gambling/gambling-icons/deposit_limit_icon-01.png",
      },
      {
        url: "./downloads/10_Safer_Gambling/gambling-icons/loss_limit_icon-01.png",
      },
      {
        url: "./downloads/10_Safer_Gambling/gambling-icons/reality_check_icon-01.png",
      },
      {
        url: "./downloads/10_Safer_Gambling/gambling-icons/self_exclusion_icon_V4.png",
      },
      {
        url: "./downloads/10_Safer_Gambling/gambling-icons/time_out_icon-01.png",
      },
    ],
  },
  {
    name: "Strategy",
    files: [
      {
        url: "./downloads/9_Strategy/Betfair Fights Back 050221.pdf",
      },
      {
        url: "./downloads/9_Strategy/BRAND HOUSE.pdf",
      },
      {
        url: "./downloads/9_Strategy/Betfair_SaferGambling_Brand_guide_Final_23.12.21.pdf",
      },
    ],
  },
  {
    name: "People brand",
    files: [
      {
        url: "./downloads/11_People_Brand/Betfair _People_ Playbook.pdf",
      },
      {
        url: "./downloads/11_People_Brand/Betfair Job ad template.pdf",
      },
    ],
  },
  {
    name: "Templates",
    files: [
      {
        url: "./downloads/12_Templates/Betfair Template v0.1.pptx",
      },
      {
        url: "./downloads/12_Templates/Betfair Template v0.1 How-to guide.pptx",
      },
    ],
  },
];
