import React from "react";
import Image from "shared/components/Image/Image";

import correct from "shared/assets/correct-icon.svg";
import incorrect from "shared/assets/incorrect-icon.svg";

import PropTypes from "prop-types";

import styles from "./Heading.module.scss";
import { addClass } from "utils/addClass";

const Heading = ({ text, icon, noMargin, smallLineHeight }) => {
  return (
    <h3
      className={addClass(
        styles.heading,
        noMargin ? styles.noMargin : "",
        smallLineHeight ? styles.smallLineHeight : ""
      )}
    >
      {text}
      {icon === "correct" ? (
        <Image noMargin src={correct} alt="Correct Icon" />
      ) : null}
      {icon === "incorrect" ? (
        <Image noMargin src={incorrect} alt="Incorrect Icon" />
      ) : null}
    </h3>
  );
};

Heading.propTypes = {
  text: PropTypes.string,
};

export default Heading;
