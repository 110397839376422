import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Subheading from "shared/components/Subheading/Subheading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import Image from "shared/components/Image/Image";
import Gallery from "shared/components/Gallery/Gallery";

import begambleaware from "shared/assets/gambling-icons/2952803_begamble_v1.svg";
import gamblingCare from "shared/assets/gambling-icons/2952803_gambling_care_v1.svg";
import gambling from "shared/assets/gambling-icons/2952803_gambling_v1.jpg";
import takeTime from "shared/assets/gambling-icons/2952803_take_time_v1.svg";

import icon1 from "shared/assets/gambling-icons/2952803_black_Icon1_v1.svg";
import icon2 from "shared/assets/gambling-icons/2952803_black_Icon2_v1.svg";
import icon3 from "shared/assets/gambling-icons/2952803_black_Icon3_v1.svg";
import icon4 from "shared/assets/gambling-icons/2952803_black_Icon4_v1.svg";
import icon5 from "shared/assets/gambling-icons/2952803_black_Icon5_v1.svg";
import icon6 from "shared/assets/gambling-icons/2952803_black_Icon6_v1.svg";
import icon7 from "shared/assets/gambling-icons/2952803_black_Icon7_v1.svg";
import icon8 from "shared/assets/gambling-icons/2952803_black_Icon8_v1.svg";
import icon9 from "shared/assets/gambling-icons/2952803_black_Icon9_v1.svg";
import icon10 from "shared/assets/gambling-icons/2952803_black_Icon10_v1.svg";
import icon11 from "shared/assets/gambling-icons/2952803_black_Icon12_v1.svg";
import icon12 from "shared/assets/gambling-icons/2952803_black_Icon13_v1.svg";
import icon13 from "shared/assets/gambling-icons/2952803_black_Icon14_v1.svg";
import icon14 from "shared/assets/gambling-icons/2952803_black_Icon15_v1.svg";
import icon15 from "shared/assets/gambling-icons/2952803_black_Icon16_v1.svg";
import icon16 from "shared/assets/gambling-icons/2952803_black_Icon17_v1.svg";
import icon17 from "shared/assets/gambling-icons/2952803_black_Icon18_v1.svg";
import icon18 from "shared/assets/gambling-icons/2952803_black_Icon19_v1.svg";
import icon19 from "shared/assets/gambling-icons/2952803_black_Icon20_v1.svg";
import icon20 from "shared/assets/gambling-icons/2952803_black_Icon21_v1.svg";
import icon21 from "shared/assets/gambling-icons/2952803_black_Icon22_v1.svg";

import iconYellow1 from "shared/assets/gambling-icons/2952803_yellow_Icon1_v1.svg";
import iconYellow2 from "shared/assets/gambling-icons/2952803_yellow_Icon2_v1.svg";
import iconYellow3 from "shared/assets/gambling-icons/2952803_yellow_Icon3_v1.svg";
import iconYellow4 from "shared/assets/gambling-icons/2952803_yellow_Icon4_v1.svg";
import iconYellow5 from "shared/assets/gambling-icons/2952803_yellow_Icon5_v1.svg";
import iconYellow6 from "shared/assets/gambling-icons/2952803_yellow_Icon6_v1.svg";
import iconYellow7 from "shared/assets/gambling-icons/2952803_yellow_Icon7_v1.svg";
import iconYellow8 from "shared/assets/gambling-icons/2952803_yellow_Icon8_v1.svg";
import iconYellow9 from "shared/assets/gambling-icons/2952803_yellow_Icon9_v1.svg";
import iconYellow10 from "shared/assets/gambling-icons/2952803_yellow_Icon10_v1.svg";
import iconYellow11 from "shared/assets/gambling-icons/2952803_yellow_Icon12_v1.svg";
import iconYellow12 from "shared/assets/gambling-icons/2952803_yellow_Icon13_v1.svg";
import iconYellow13 from "shared/assets/gambling-icons/2952803_yellow_Icon14_v1.svg";
import iconYellow14 from "shared/assets/gambling-icons/2952803_yellow_Icon15_v1.svg";
import iconYellow15 from "shared/assets/gambling-icons/2952803_yellow_Icon16_v1.svg";
import iconYellow16 from "shared/assets/gambling-icons/2952803_yellow_Icon17_v1.svg";
import iconYellow17 from "shared/assets/gambling-icons/2952803_yellow_Icon18_v1.svg";
import iconYellow18 from "shared/assets/gambling-icons/2952803_yellow_Icon19_v1.svg";
import iconYellow19 from "shared/assets/gambling-icons/2952803_yellow_Icon20_v1.svg";
import iconYellow20 from "shared/assets/gambling-icons/2952803_yellow_Icon21_v1.svg";
import iconYellow21 from "shared/assets/gambling-icons/2952803_yellow_Icon22_v1.svg";
import LogosSection from "shared/components/LogosSection/LogosSection";

const SaferGamblingTemplate = () => {
  return (
    <Fragment>
      <Heading text="Safer Gambling" />

      <Paragraph>
        <p>
          We've created a suite of icons to help visualise the themes and tools
          that we're talking about in our Safer Gambling comms.
        </p>
      </Paragraph>

      <Gallery
        download
        rowOf7
        bigImagesOnly
        images={[
          {
            src: icon1,
            alt: "icon image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon1_v1.svg",
          },
          {
            src: icon2,
            alt: "icon image 2",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon2_v1.svg",
          },
          {
            src: icon3,
            alt: "icon image 3",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon3_v1.svg",
          },
          {
            src: icon4,
            alt: "icon image 4",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon4_v1.svg",
          },
          {
            src: icon5,
            alt: "icon image 5",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon5_v1.svg",
          },
          {
            src: icon6,
            alt: "icon image 6",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon6_v1.svg",
          },
          {
            src: icon7,
            alt: "icon image 7",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon7_v1.svg",
          },
          {
            src: icon8,
            alt: "icon image 8",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon8_v1.svg",
          },
          {
            src: icon9,
            alt: "icon image 9",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon9_v1.svg",
          },
          {
            src: icon10,
            alt: "icon image 10",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon10_v1.svg",
          },
          {
            src: icon11,
            alt: "icon image 11",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon12_v1.svg",
          },
          {
            src: icon12,
            alt: "icon image 12",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon13_v1.svg",
          },
          {
            src: icon13,
            alt: "icon image 13",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon14_v1.svg",
          },
          {
            src: icon14,
            alt: "icon image 14",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon15_v1.svg",
          },
          {
            src: icon15,
            alt: "icon image 15",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon16_v1.svg",
          },
          {
            src: icon16,
            alt: "icon image 16",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon17_v1.svg",
          },
          {
            src: icon17,
            alt: "icon image 17",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon18_v1.svg",
          },
          {
            src: icon18,
            alt: "icon image 18",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon19_v1.svg",
          },
          {
            src: icon19,
            alt: "icon image 19",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon20_v1.svg",
          },
          {
            src: icon20,
            alt: "icon image 20",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon21_v1.svg",
          },
          {
            src: icon21,
            alt: "icon image 21",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_black_Icon22_v1.svg",
          },
        ]}
      />

      <Gallery
        download
        rowOf7
        bigImagesOnly
        images={[
          {
            src: iconYellow1,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon1_v1.svg",
          },
          {
            src: iconYellow2,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon2_v1.svg",
          },
          {
            src: iconYellow3,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon3_v1.svg",
          },
          {
            src: iconYellow4,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon4_v1.svg",
          },
          {
            src: iconYellow5,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon5_v1.svg",
          },
          {
            src: iconYellow6,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon6_v1.svg",
          },
          {
            src: iconYellow7,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon7_v1.svg",
          },
          {
            src: iconYellow8,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon8_v1.svg",
          },
          {
            src: iconYellow9,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon9_v1.svg",
          },
          {
            src: iconYellow10,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon10_v1.svg",
          },
          {
            src: iconYellow11,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon12_v1.svg",
          },
          {
            src: iconYellow12,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon13_v1.svg",
          },
          {
            src: iconYellow13,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon14_v1.svg",
          },
          {
            src: iconYellow14,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon15_v1.svg",
          },
          {
            src: iconYellow15,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon16_v1.svg",
          },
          {
            src: iconYellow16,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon17_v1.svg",
          },
          {
            src: iconYellow17,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon18_v1.svg",
          },
          {
            src: iconYellow18,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon19_v1.svg",
          },
          {
            src: iconYellow19,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon20_v1.svg",
          },
          {
            src: iconYellow20,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon21_v1.svg",
          },
          {
            src: iconYellow21,
            alt: "icon yellow image 1",
            downloadUrl:
              "./downloads/10_Safer_Gambling/gambling-icons/2952803_yellow_Icon22_v1.svg",
          },
        ]}
      />

      <DownloadButton
        title="Download Safer Gambling icons"
        href="./downloads/10_Safer_Gambling/Safer_Gambling_Icons.zip"
      />

      <Heading text="UK" />

      <Paragraph mediumMargin>
        <p>
          Below are the safer gambling logos we must carry on advertising in
          both the UK and Ireland:
        </p>
      </Paragraph>

      <LogosSection flex paddingBottom>
        <Image src={takeTime} alt="Take time" noMargin />
        <Image src={begambleaware} alt="Be gamble aware" noMargin />
      </LogosSection>

      <Heading text="Ireland" />

      <LogosSection flex paddingBottom>
        <Image src={gambling} alt="Gambling" noMargin />
        <Image src={gamblingCare} alt="Gambling Care" noMargin />
      </LogosSection>
    </Fragment>
  );
};

export default SaferGamblingTemplate;
