import React, { useState } from "react";
import Icon from "shared/components/Icon/Icon";
import SlideToggle from "react-slide-toggle";
import { addClass } from "utils/addClass";
import { useCurrentlyActiveSection } from "providers/CurrentlyActiveSection";
import useScrollToSection from "shared/hooks/useScrollToSection";

import styles from "./ToggledElement.module.scss";

const ToggledElement = ({ item, activeItem, isMenuVisible }) => {
  const [isToggled, setIsToggled] = useState(true);

  const { setCurrentlyActiveSectionId } = useCurrentlyActiveSection();
  const { scrollToSection } = useScrollToSection();

  const clickHandler = (el) => {
    const id = el.target.dataset.id;
    setCurrentlyActiveSectionId(id);
    window.location.hash = id;

    const sectionToScroll = document.getElementById("main-layout");

    scrollToSection(sectionToScroll, 0, true);
    isMenuVisible(false);
  };

  return (
    <SlideToggle duration={300}>
      {({ toggle, setCollapsibleElement }) => (
        <div className={styles.accordion}>
          <div
            className={addClass(
              styles.collapsibleElement,
              isToggled ? styles.isToggled : ""
            )}
            ref={setCollapsibleElement}
          >
            <div className={styles.list}>
              {item.list.map((item, index) => (
                <button
                  key={`${item.id}-${index}`}
                  type="button"
                  className={addClass(
                    styles.button,
                    activeItem === item.id ? styles.active : ""
                  )}
                  data-id={item.id}
                  onClick={clickHandler}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
          <button
            type="button"
            className={addClass(
              styles.accordionTitle,
              isToggled ? styles.isToggled : ""
            )}
            onClick={() => {
              toggle();
              setIsToggled(!isToggled);
            }}
          >
            {item.accordionTitle}
            <Icon className={styles.icon} icon="chevron-down" />
          </button>
        </div>
      )}
    </SlideToggle>
  );
};

export default ToggledElement;
