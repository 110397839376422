import React from 'react';

import { CurrentlyPlayingMediaProvider } from 'providers/CurrentlyPlayingMedia';
import { CurrentlyActiveSectionProvider } from 'providers/CurrentlyActiveSection';
import { MenuVisibilityProvider } from 'providers/MenuVisibility';

import Homepage from 'pages/homepage/Homepage';

const App = () => {
  return (
    <MenuVisibilityProvider>
      <CurrentlyPlayingMediaProvider>
        <CurrentlyActiveSectionProvider>
          <Homepage />
        </CurrentlyActiveSectionProvider>
      </CurrentlyPlayingMediaProvider>
    </MenuVisibilityProvider>
  );
}

export default App;
