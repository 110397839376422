import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import Header from "../Header/Header";
import { ReactComponent as Arrows } from "./intro-arrows.svg";

import useScrollToSection from "shared/hooks/useScrollToSection";

import Wrapper from "../Wrapper/Wrapper";
import Paragraph from "../Paragraphs/Paragraph";
import ScrollDownButton from "../ScrollDownButton/ScrollDownButton";

import styles from "./Intro.module.scss";

gsap.registerPlugin(DrawSVGPlugin);

const Intro = () => {
  const { scrollToSection } = useScrollToSection();
  const sectionToScroll = document.getElementById("main-layout");
  const [allowInteraction, setAllowInteraction] = useState(false);
  const tl = useRef();
  const introRef = useRef();

  useEffect(() => {
    const arrows = introRef.current.querySelector(".intro-arrows");
    const arrowLeftStroke = arrows.querySelector(".intro-arrow-left-stroke");
    const arrowRightStroke = arrows.querySelector(".intro-arrow-right-stroke");
    tl.current = gsap
      .timeline({
        onComplete: () => {
          changeMainLayoutPosition();
        },
      })
      .set([arrowRightStroke, ], {
        y: "-25%", // Start off-screen vertically centered
      })
      .set([arrowLeftStroke], {
        y: "25%", // Start off-screen vertically centered
      })

      .to(
        arrowRightStroke,
        {
          opacity: 0.1, // Fade in
          y: "25%", // Ease from top to bottom
          duration: 1.5, // Duration of the ease-in effect
          ease: "power3.inOut", // Easing function for ease-in
        },
        "<" // Start this animation as soon as the previous one begins
      )

      .to(
        arrowLeftStroke,
        {
          opacity: 0.1, // Fade in
          y: "-25%", // Ease from top to bottom
          duration: 1.5, // Duration of the ease-in effect
          ease: "power3.inOut", // Easing function for ease-in
        },
        "<" // Start this animation as soon as the previous one begins
      )
      // .to(
      //   [arrowLeftStroke, arrowRightStroke],
      //   {
      //     opacity: 0, // Fade out
      //     y: "0%", // Return to off-screen position
      //     duration: 1.5, // Duration of the ease-out effect
      //     ease: "power3.inOut", // Easing function for ease-out
      //   },
      //   "+=0.5" // Delay the ease-out effect after the ease-in effect
      // );
  }, []);

  const changeMainLayoutPosition = () => {
    document.documentElement.style.setProperty(
      "--main-layout-position",
      "static"
    );
    setAllowInteraction(true);
  };

  const scrollToMainLayout = () => {
    if (!allowInteraction) return;

    scrollToSection(sectionToScroll, 1, true);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--main-layout-position",
      "fixed"
    );
  }, []);

  useEffect(() => {
    window.addEventListener("wheel", scrollToMainLayout);
    return () => window.removeEventListener("wheel", scrollToMainLayout);
  }, []);

  return (
    <div className={styles.intro} ref={introRef}>
      <Header />
      <div className={styles.container}>
        <Arrows />
        <Wrapper className={styles.wrapper}>
          <div className={styles.content}>
            <h1 className={styles.title}>Betfair Brand Hub</h1>
            <h2 className={styles.subheading}>
              Welcome to Betfair's Playbook.
            </h2>
            <Paragraph smallFont className={styles.paragraph}>
              <p>
                It'll give you the tools you need to bring your great ideas into
                the world, while keeping them looking and sounding consistent
                with everything else we do.
              </p>
              <p>
                As the original Game Changer, we got to where we are now by
                constantly innovating and evolving. And whilst we celebrate the
                mavericks and the rule breakers, our brand guidelines are
                sacred.
              </p>
              <p>
                So have a browse and download what you need to bring the brand
                to life and if something doesn’t look right or you have any
                questions, give the brand team a shout.
              </p>
            </Paragraph>
          </div>
          <ScrollDownButton onClick={scrollToMainLayout} />
        </Wrapper>
      </div>
    </div>
  );
};

export default Intro;
