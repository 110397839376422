import React from "react";

import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import ColorTile from "../ColorTile/ColorTile";
import Subheading from "shared/components/Subheading/Subheading";

import styles from "./ColorPalette.module.scss";
import { addClass } from "utils/addClass";

const ColorPalette = ({
  palettes,
  btnTitle,
  btnHref,
  subheading,
  noMargin,
}) => {
  return (
    <div
      className={addClass(styles.container, noMargin ? styles.noMargin : "")}
    >
      {subheading && <Subheading text={subheading} />}
      <div className={styles.grid}>
        {palettes.map((palette, i) => (
          <ColorTile
            key={`${i} - ${palette.title}`}
            title={palette.title}
            backgroundColor={palette.backgroundColor}
            backgroundColorRgba={palette.backgroundColorRgba}
          />
        ))}
      </div>
      <DownloadButton noMargin title={btnTitle} href={btnHref} />
    </div>
  );
};

export default ColorPalette;
