import React, { useRef, useState, useEffect } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useWindowResize from 'shared/hooks/useWindowResize';

import Icon from '../Icon/Icon';
import useScrollToSection from 'shared/hooks/useScrollToSection';

import classNames from 'classnames/bind';
import styles from './ScrollUpButton.module.scss';
let cx = classNames.bind(styles);

gsap.registerPlugin(ScrollTrigger);

const ScrollUpButton = () => {
    const [isActive, setIsActive] = useState(false);
    const { scrollToSection } = useScrollToSection();
    const buttonRef = useRef();
    const clickHandler = () => {
        const sectionToScroll = document.getElementById('main-layout');
        scrollToSection(sectionToScroll, 0.5, true);
    };

    const tl = useRef();

    const windowResize = useWindowResize();

    useEffect(() => {
        tl.current = gsap.timeline().to(buttonRef.current, {
            scrollTrigger: {
                trigger: document.body,
                scrub: true,
                start: '500px',
                end: '99999px',
                onEnter: () => setIsActive(true),
                onEnterBack: () => setIsActive(true),
                onLeave: () => setIsActive(false),
                onLeaveBack: () => setIsActive(false),
            },
        });
    }, [windowResize]);

    const buttonClasses = cx({
        button: true,
        active: isActive,
    });
    return (
        <button type='button' className={buttonClasses} aria-label='Scroll up' onClick={clickHandler} ref={buttonRef}>
            <Icon icon='scroll-up-arrow' className={styles.icon} />
        </button>
    );
};

export default ScrollUpButton;
