import React from "react";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import PropTypes from "prop-types";
import Wrapper from "../Wrapper/Wrapper";
import Icon from "shared/components/Icon/Icon";

import styles from "./TopSection.module.scss";

const TopSection = ({ title }) => {
  return (
    <div className={styles.container}>
      <Wrapper className={styles.wrapper}>
        <Icon icon="logo" className={styles.logo} />
        <h1 className={styles.title}>{title}</h1>
        <HamburgerButton />
      </Wrapper>
    </div>
  );
};

TopSection.propTypes = {
  title: PropTypes.string,
};

export default TopSection;
