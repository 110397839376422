import React from 'react';

import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import styles from './File.module.scss';

const File = ({ src }) => {
    const filenameAndExtension = src.substring(src.lastIndexOf('/') + 1);
    const splitted = filenameAndExtension.split('.');
    const extension = splitted.pop();
    const filename = splitted.join('.');

    return (
        <a href={encodeURI(src)} className={styles.link} target='_blank' rel='noopener noreferrer'>
            <span className={styles.name}>{filename}</span>
            <span className={styles.extension}>{extension}</span>
            <Icon icon='download' className={styles.icon} />
        </a>
    );
};

File.propTypes = {
    src: PropTypes.string.isRequired,
};

export default File;
