import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ScrollIcon } from 'shared/assets/scroll.svg';

import styles from './ScrollDownButton.module.scss';

const ScrollDownButton = forwardRef(({ onClick }, ref) => {
    return (
        <button type='button' className={styles.button} onClick={onClick} ref={ref}>
            <span className={styles.title}>Click or Scroll To Get Started</span>
            <ScrollIcon className={styles.icon} />
        </button>
    );
});

ScrollDownButton.propTypes = {
    onClick: PropTypes.func,
};

export default ScrollDownButton;
