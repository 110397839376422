import React, { Fragment } from "react";

import SwiperCore, { Pagination } from "swiper";

import Heading from "shared/components/Heading/Heading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import BrandLogos from "shared/components/BrandLogos/BrandLogos";

import betfairYellowLogoBlackBgWide from "shared/assets/bf-yellow-logo-black-bg-wide.svg";
import betfairBlackLogoYellowBgWide from "shared/assets/bf-black-logo-yellow-bg-wide.svg";
import ImageAndInfoSection from "shared/components/ImageAndInfoSection/ImageAndInfoSection";

SwiperCore.use([Pagination]);

const LogosTemplate = () => {
  return (
    <Fragment>
      <Heading text="Brand logo" />

      <Paragraph>
        <p>
          This is the Betfair brand logo and is to be used where possible. We
          want people to recognise our logo whenever they see it. Therefore
          consistent appearance and application is critical. The Betfair logo
          consists of two elements: the logotype and the arrows. The
          relationship between these elements should never be altered. Official
          brand artwork should always be used. The logo should never be redrawn
          or altered in any way.
        </p>
      </Paragraph>

      <BrandLogos
        yellowLogo={betfairYellowLogoBlackBgWide}
        blackLogo={betfairBlackLogoYellowBgWide}
      />

      <Heading text="Colour" />

      <ImageAndInfoSection
        blackLogo={betfairBlackLogoYellowBgWide}
        yellowLogo={betfairYellowLogoBlackBgWide}
      />
    </Fragment>
  );
};

export default LogosTemplate;
