import React from "react";

import styles from "./ColorTile.module.scss";
import Subheading from "shared/components/Subheading/Subheading";

const ColorTile = ({
  backgroundColor,
  title,
  backgroundColorRgba,
  subtitle,
}) => {
  return (
    <div className={styles.container}>
      {subtitle && <Subheading text={subtitle} />}
      <div
        style={{ backgroundColor: backgroundColor }}
        className={styles.backgroundColor}
      ></div>
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <span className={styles.label}>{backgroundColor}</span>
        <span className={styles.label}>{backgroundColorRgba}</span>
      </div>
    </div>
  );
};

export default ColorTile;
