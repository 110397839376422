import React from "react";

import PropTypes from "prop-types";

import classNames from "classnames/bind";
import styles from "./Image.module.scss";
let cx = classNames.bind(styles);

const Image = ({
  src,
  alt,
  smallMargin,
  mediumMargin,
  className,
  noMargin,
  whiteBg,
  contain,
  fullWidth,
}) => {
  const imageClasses = cx({
    image: true,
    smallMargin: smallMargin,
    mediumMargin: mediumMargin,
    noMargin: noMargin,
    whiteBg: whiteBg,
    contain: contain,
    fullWidth: fullWidth,
    [className]: className,
  });
  return <img src={src} alt={alt} className={imageClasses} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  smallMargin: PropTypes.bool,
  mediumMargin: PropTypes.bool,
  noMargin: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default Image;
