import React from 'react';

import Icon from '../Icon/Icon';
import Wrapper from '../Wrapper/Wrapper';

import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <Wrapper className={styles.wrapper}>
                <p className={styles.text}>If you have any questions, drop us a mail <a href="mailto:anna.glyn-davies@ppb.com">anna.glyn-davies@ppb.com</a></p>
            </Wrapper>
        </footer>
    )
}

export default Footer;