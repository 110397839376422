import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import Image from "shared/components/Image/Image";

import playbookBetfair from "shared/assets/playbook-page/playbook-betfair.jpg";
import playbookBetfairCasino from "shared/assets/playbook-page/playbook-betfair-casino.jpg";
import playbookBetfairPinball from "shared/assets/playbook-page/playbook-betfair-pinball.jpg";


const PlaybooksTemplate = () => {
  return (
    <Fragment>
      <Heading text="Playbooks" />

      <Paragraph>
        <p>
          Playbooks are guides you can use to ensure consistency when creating
          assets, copy or new ideas based on campaigns or creative that have
          already gone live.
        </p>
      </Paragraph>

      <Heading text="UKI VI" />

      <Image src={playbookBetfair} alt="playbook Betfair" />

      <DownloadButton
        title="Download UKI VI playbook"
        href="./downloads/8_Playbooks for Microsite/BF-VI-Toolkit_September2020_2.0.pdf"
      />

      <Heading text="Casino Creative Strategy Guideline" />

      <Image src={playbookBetfairCasino} alt="playbook Betfair Casino" />

      <DownloadButton
        title="Download Casino Creative Strategy Guideline"
        href="./downloads/8_Playbooks for Microsite/Betfair Casino Campaign Guidelines - 2023 Final.pdf"
      />

      <Heading text="Prize Pinball" />

      <Image src={playbookBetfairPinball} alt="playbook Betfair Pinball" />

      <DownloadButton
        title="Download Prize Pinball playbook"
        href="./downloads/8_Playbooks for Microsite/Betfair Prize Pinball VI Guidelines - July 2023.pdf"
      />

    </Fragment>
  );
};

export default PlaybooksTemplate;
