import React from "react";

import PropTypes from "prop-types";
import Icon from "../Icon/Icon";

import useScrollToSection from "shared/hooks/useScrollToSection";
import { useCurrentlyActiveSection } from "providers/CurrentlyActiveSection";

import styles from "./DownloadButton.module.scss";
import { addClass } from "utils/addClass";

const DownloadButton = ({ title, href, noMargin }) => {
  const { setCurrentlyActiveSectionId } = useCurrentlyActiveSection();
  const { scrollToSection } = useScrollToSection();

  const clickHandler = () => {
    setCurrentlyActiveSectionId("downloads");
    window.location.hash = "downloads";

    const sectionToScroll = document.body;
    scrollToSection(sectionToScroll, 0, true);
  };

  return (
    <>
      {!href && (
        <button
          type="button"
          className={addClass(styles.button, noMargin ? styles.noMargin : "")}
          onClick={clickHandler}
        >
          <Icon icon="download-arrow" className={styles.icon} />
          {title}
        </button>
      )}
      {href && (
        <a
          href={encodeURI(href)}
          className={addClass(styles.button, noMargin ? styles.noMargin : "")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="download-arrow" className={styles.icon} />
          {title}
        </a>
      )}
    </>
  );
};

DownloadButton.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
};

export default DownloadButton;
