import React, { useState, useEffect, useCallback, Fragment } from 'react';

import PropTypes from 'prop-types';
import Button from '../Button/Button';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Icon from '../Icon/Icon';

import classNames from 'classnames/bind';
import styles from './VideoLightbox.module.scss';
let cx = classNames.bind(styles);

const VideoLightbox = ({ url }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const openLightbox = () => {
        setIsPlaying(true);
    }

    const closeLightbox = () => {
        setIsPlaying(false);
    }

    const lightboxClasses = cx({
        lightbox: true,
        active: isPlaying
    });

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            closeLightbox();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
    return (
        <Fragment>
            <Button type="text" title="Show video" onClick={openLightbox} leftAlign />
            <div className={lightboxClasses}>
                <div className={styles.overlay} onClick={closeLightbox}></div>
                <button type="button" className={styles.closeButton} onClick={closeLightbox}>
                    <Icon icon="cross" className={styles.closeIcon} />
                </button>
                {isPlaying &&
                    <VideoPlayer url={url} playing={isPlaying} />
                }
            </div>
        </Fragment>
    )
}

VideoLightbox.propTypes = {
    title: PropTypes.string,
    src: PropTypes.string
}

export default VideoLightbox;