import React, { forwardRef } from "react";

import PropTypes from "prop-types";

import classNames from "classnames/bind";
import styles from "./Paragraph.module.scss";
let cx = classNames.bind(styles);

const Paragraph = forwardRef(
  (
    {
      children,
      white,
      smallMargin,
      smallFont,
      className,
      red,
      mediumMargin,
      noMargin,
    },
    ref
  ) => {
    const containerClasses = cx({
      container: true,
      white: white,
      red: red,
      smallFont: smallFont,
      smallMargin: smallMargin,
      mediumMargin: mediumMargin,
      noMargin: noMargin,
      [className]: className,
    });
    return (
      <div className={containerClasses} ref={ref}>
        {children}
      </div>
    );
  }
);

Paragraph.propTypes = {
  children: PropTypes.node,
  white: PropTypes.bool,
  red: PropTypes.bool,
  smallFont: PropTypes.bool,
  smallMargin: PropTypes.bool,
  mediumMargin: PropTypes.bool,
  noMargin: PropTypes.bool,
  className: PropTypes.string,
};

export default Paragraph;
