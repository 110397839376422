import React from 'react';

import PropTypes from 'prop-types';

import styles from './Files.module.scss';

const Files = ({ children }) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

Files.propTypes = {
    children: PropTypes.node
}

export default Files;