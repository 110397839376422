import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './Button.module.scss';
let cx = classNames.bind(styles);

const Button = forwardRef(({ type, title, onClick, leftAlign, marginBottom, view = 'primary', disableHover, scratchedText }, ref) => {
    const containerClasses = cx({
        container: true,
        leftAlign: leftAlign,
        marginBottom: marginBottom
    });
    const buttonClasses = cx({
        button: true,
        [view]: view,
        disableHover: disableHover
    });
    return (
        <div className={containerClasses} ref={ref}>
            <button className={buttonClasses} type={type} onClick={onClick}>
                {scratchedText &&
                    <span className={styles.scratchedText}>{scratchedText}</span>
                }
                {title}
            </button>
        </div>
    )
})

Button.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    scratchedText: PropTypes.string,
    onClick: PropTypes.func,
    leftAlign: PropTypes.bool,
    view: PropTypes.oneOf(['primary', 'secondary', 'price']),
    disableHover: PropTypes.bool
}

export default Button;
