import React from 'react';

import Icon from '../Icon/Icon';

import { useMenuVisibility } from 'providers/MenuVisibility';

import classNames from 'classnames/bind';
import styles from './HamburgerButton.module.scss';
let cx = classNames.bind(styles);

const HamburgerButton = () => {
    const { isMenuVisible, setIsMenuVisible } = useMenuVisibility();

    const buttonClasses = cx({
        button: true,
        active: isMenuVisible
    });

    const clickHandler = () => {
        setIsMenuVisible(!isMenuVisible);
    }
    return (
        <button type="button" className={buttonClasses} onClick={clickHandler}>
            <Icon icon="menu" className={`${styles.icon} ${styles.hiddenOnActive}`} />
            <Icon icon="cross" className={`${styles.icon} ${styles.visibleOnActive}`} />
        </button>
    )
}

export default HamburgerButton;