import React from 'react';

import styles from './FileHeader.module.scss';

const FileHeader = () => {
    return (
        <header className={styles.header}>
            <span className={styles.title}>Filename</span>
            <span className={styles.title}>Type</span>
        </header>
    )
}

export default FileHeader;