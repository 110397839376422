import React from 'react';

import Icon from '../Icon/Icon';
import Wrapper from '../Wrapper/Wrapper';

import styles from './Header.module.scss';

const Header = () => {
    return (
        <header className={styles.header}>
            <Wrapper className={styles.wrapper}>
                <Icon icon="logo" className={styles.logo} />
            </Wrapper>
        </header>
    )
}

export default Header;