import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Accordion from "shared/components/Accordion/Accordion";
import Files from "shared/components/Files/Files";
import FileHeader from "shared/components/FileHeader/FileHeader";
import File from "shared/components/File/File";

import { downloadsData } from "./downloadsData";

const DownloadsTemplate = () => {
    return (
        <Fragment>
            <Heading text="Downloads" />
            <Files>
                <FileHeader />
                {downloadsData.map(folder => (
                    <Accordion title={folder.name} key={folder.name}>
                        {folder.files.map(file => (

                            <File src={file.url} key={file.url} />
                        ))}
                    </Accordion>
                ))}
            </Files>
        </Fragment>
    )
};

export default DownloadsTemplate;