import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Subheading from "shared/components/Subheading/Subheading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import BannerTextSection from "shared/components/BannerTextSection/BannerTextSection";

import headingMobile from "shared/assets/font-banners/heading.jpg";
import headingDesktop from "shared/assets/font-banners/2852803_bannerfont1_v1.png";

import numberMobile from "shared/assets/font-banners/number.jpg";
import numberDesktop from "shared/assets/font-banners/2852803_bannerfont2_v1.png";

import capitalMobile from "shared/assets/font-banners/capitalisation.jpg";
import capitalDesktop from "shared/assets/font-banners/2852803_bannerfont3_v1.png";

const FontTemplate = () => {
  return (
    <Fragment>
      <Heading text="Font" />

      <Paragraph>
        <p>
          Our font is Noto Sans. It comes in four weights. These weights,
          combined with careful use of size and colour provides plenty of
          flexibility in creating dynamic and elegant layouts.
        </p>
      </Paragraph>

      <DownloadButton
        title="Download Brand Font"
        href="./downloads/4_Typeface for Microsite/NotoSans.zip"
      />

      <Heading noMargin text="Headings & subheads" />
      <Subheading marginL text="General rules" />

      <BannerTextSection
        desktopBanner={headingDesktop}
        mobileBanner={headingMobile}
        bottomText={[
          "Subheads are set in Noto Sans medium, with emphasised words in bold. Optical kerning and tracking at -15.",
        ]}
        asideText={[
          "Headlines are set in Noto Sans bold, optical kerning and -25 tracking. Noto Sans has very generous tracking so it’s important to apply these settings to get the desired aesthetic.",
          "Headlines and subheads use a sizing ratio of 2:1. In this case the headline is 68pt and the subhead is 34pt.",
        ]}
      />

      <BannerTextSection
        desktopBanner={numberDesktop}
        mobileBanner={numberMobile}
        paddingRight
        title="Large numbers in headlines"
        bottomText={[
          "Headlines that use a big number are not subject to the 2:1 ratio. In these cases, let the requirements of the layout guide you in finding a suitable type size.",
        ]}
      />

      <BannerTextSection
        desktopBanner={capitalDesktop}
        mobileBanner={capitalMobile}
        paddingRight
        title="Capitalisation"
        bottomText={[
          "Capitalisation can be used to emphasise a key message. Caps should be applied to 1 or 2 words in a headline, and should only be used when the headline is more than 4 words long. 1 capitalised word in a very short headline is likely to look like a mistake, or to confuse the reader.",
          "Don’t say: <strong>‘£5 FREE Bet</strong> <br/> Do say: <strong>‘Take ya shot at a FREE Jackpot’</strong>",
        ]}
      />
    </Fragment>
  );
};

export default FontTemplate;
