import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Subheading from "shared/components/Subheading/Subheading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import Gallery from "shared/components/Gallery/Gallery";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";

import racingImage1 from "shared/assets/racing-imagery/RP_ID00343458.jpg";
import racingImage2 from "shared/assets/racing-imagery/RP_ID00367890.jpg";

import sport1 from "shared/assets/imagery/sport1_v1.png";
import sport2 from "shared/assets/imagery/sport2_v1.png";
import sport3 from "shared/assets/imagery/sport3_v1.png";
import sport4 from "shared/assets/imagery/sport4_v1.png";

import casino1 from "shared/assets/imagery/3523440_casinoproduct1_v1.png";
import casino2 from "shared/assets/imagery/3523440_casinoproduct2_v1.png";
import casino3 from "shared/assets/imagery/3523440_casinoproduct3_v1.png";
import casino4 from "shared/assets/imagery/3523440_casinoproduct4_v1.png";
import casino5 from "shared/assets/imagery/3523440_casinoproduct5_v1.png";
import casino6 from "shared/assets/imagery/3523440_casinoproduct6_v2.png";
import casino7 from "shared/assets/imagery/3523440_casinocampaign1_v1.png";
import casino8 from "shared/assets/imagery/3523440_casinocampaign2_v1.png";
import casino9 from "shared/assets/imagery/3523440_casinocampaign3_v1.png";
import casino10 from "shared/assets/imagery/3523440_casinocampaign4_v1.png";
import casino11 from "shared/assets/imagery/3523440_casinocampaign5_v1.png";
import casino12 from "shared/assets/imagery/3523440_casinocampaign6_v1.png";
import casino13 from "shared/assets/imagery/3523440_casinoproduct7_v1.png";
import casino14 from "shared/assets/imagery/3523440_casinoproduct8_v1.png";

import poker1 from "shared/assets/imagery/2952803_poker1_v1.png";
import poker2 from "shared/assets/imagery/2952803_poker2_v1.png";
import poker3 from "shared/assets/imagery/2952803_poker3_v1.png";
import poker4 from "shared/assets/imagery/2952803_poker4_v1.png";

import bingo1 from "shared/assets/imagery/2952803_bingo1_v1.png";
import bingo2 from "shared/assets/imagery/2952803_bingo2_v1.png";
import bingo3 from "shared/assets/imagery/2952803_bingo3_v1.png";
import bingo4 from "shared/assets/imagery/2952803_bingo4_v1.png";

import dos from "shared/assets/imagery/2952803_dos_v1.jpg";
import donts from "shared/assets/imagery/2952803_donts_v1.jpg";

import dos2 from "shared/assets/imagery/2952803_dos2_v1.jpg";
import donts2 from "shared/assets/imagery/2952803_donts2_v1.jpg";

import pbExample1 from "shared/assets/imagery/playbooks-colour/Exclamation-Mark-Yellow.png";
import pbExample2 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-0-1-Yellow.png";
import pbExample3 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-1-Yellow.png";
import pbExample4 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-2-Yellow.png";
import pbExample5 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-3-Yellow.png";
import pbExample6 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-4-Yellow.png";
import pbExample7 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-5-Yellow.png";
import pbExample8 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-6-Yellow.png";
import pbExample9 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-7-Yellow.png";
import pbExample10 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-8-Yellow.png";
import pbExample11 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-9-Yellow.png";
import pbExample12 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-Line-Yellow.png";
import pbExample13 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Asterix-Yellow.png";
import pbExample14 from "shared/assets/imagery/playbooks-colour/Playbook-Elements--BF-Arrows-Yellow.png";
import pbExample15 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Card-Yellow.png";
import pbExample16 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Circle-1-Yellow.png";
import pbExample17 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Circle-2-Yellow.png";
import pbExample18 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Circle-3-Yellow.png";
import pbExample19 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Corner-Flag-Yellow.png";
import pbExample20 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Corner-Pitch-Yellow.png";
import pbExample21 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Diamond-Yellow.png";
import pbExample22 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Equation-Yellow.png";
import pbExample23 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Flip-Chart-Yellow.png";
import pbExample24 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Football-Picth-Yellow.png";
import pbExample25 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Football-Yellow.png";
import pbExample26 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Lock-Yellow.png";
import pbExample27 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Multiple-Underline-2-Yellow.png";
import pbExample28 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Multiple-Underline-Yellow.png";
import pbExample29 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-OXO-Yellow.png";
import pbExample30 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Parachute-Yellow.png";
import pbExample31 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Piggy-Bank-Yellow.png";
import pbExample32 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Single-Line-Yellow.png";
import pbExample33 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Star-Yellow.png";
import pbExample34 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Strategy-Yellow.png";
import pbExample35 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Tick-Yellow.png";
import pbExample36 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Umbrella-Yellow.png";
import pbExample37 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Wavy-Line-2-Yellow.png";
import pbExample38 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Wavy-Line-Yellow.png";
import pbExample39 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Whistle-Yellow.png";
import pbExample40 from "shared/assets/imagery/playbooks-colour/Exclamation-Mark-Black.png";
import pbExample41 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-0-1-Black.png";
import pbExample42 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-1-Black.png";
import pbExample43 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-2-Black.png";
import pbExample44 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-3-Black.png";
import pbExample45 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-4-Black.png";
import pbExample46 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-5-Black.png";
import pbExample47 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-6-Black.png";
import pbExample48 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-7-Black.png";
import pbExample49 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-8-Black.png";
import pbExample50 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-9-Black.png";
import pbExample51 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Arrow-Line-Black.png";
import pbExample52 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Asterix-Black.png";
import pbExample53 from "shared/assets/imagery/playbooks-colour/Playbook-Elements--BF-Arrows-Black.png";
import pbExample54 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Card-Black.png";
import pbExample55 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Circle-1-Black.png";
import pbExample56 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Circle-2-Black.png";
import pbExample57 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Circle-3-Black.png";
import pbExample58 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Corner-Flag-Black.png";
import pbExample59 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Corner-Pitch-Black.png";
import pbExample60 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Diamond-Black.png";
import pbExample61 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Equation-Black.png";
import pbExample62 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Flip-Chart-Black.png";
import pbExample63 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Football-Picth-Black.png";
import pbExample64 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Football-Black.png";
import pbExample65 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Lock-Black.png";
import pbExample66 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Multiple-Underline-2-Black.png";
import pbExample67 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Multiple-Underline-Black.png";
import pbExample68 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-OXO-Black.png";
import pbExample69 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Parachute-Black.png";
import pbExample70 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Piggy-Bank-Black.png";
import pbExample71 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Single-Line-Black.png";
import pbExample72 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Star-Black.png";
import pbExample73 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Strategy-Black.png";
import pbExample74 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Tick-Black.png";
import pbExample75 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Umbrella-Black.png";
import pbExample76 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Wavy-Line-2-Black.png";
import pbExample77 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Wavy-Line-Black.png";
import pbExample78 from "shared/assets/imagery/playbooks-colour/Playbook-Elements-Whistle-Black.png";
import pbExample79 from "shared/assets/imagery/2952803_playbook1_v1.png";
import pbExample80 from "shared/assets/imagery/2952803_playbook2_v1.png";
import pbExample81 from "shared/assets/imagery/2952803_playbook3_v1.png";
import pbExample82 from "shared/assets/imagery/2952803_playbook4_v1.png";
import pbExample83 from "shared/assets/imagery/2952803_playbook5_v1.png";
import pbExample84 from "shared/assets/imagery/2952803_playbook6_v1.png";
import pbExample85 from "shared/assets/imagery/2952803_playbook7_v1.png";
import pbExample86 from "shared/assets/imagery/2952803_playbook8_v1.png";
import pbExample87 from "shared/assets/imagery/2952803_playbook9_v1.png";
import pbExample88 from "shared/assets/imagery/2952803_playbook10_v1.png";
import pbExample89 from "shared/assets/imagery/2952803_playbook11_v1.png";
import pbExample90 from "shared/assets/imagery/2952803_playbook12_v1.png";
import pbExample91 from "shared/assets/imagery/2952803_playbook13_v1.png";
import pbExample92 from "shared/assets/imagery/2952803_playbook14_v1.png";
import pbExample93 from "shared/assets/imagery/2952803_playbook15_v1.png";
import pbExample94 from "shared/assets/imagery/2952803_playbook16_v1.png";
import pbExample95 from "shared/assets/imagery/2952803_playbook17_v1.png";
import pbExample96 from "shared/assets/imagery/2952803_playbook18_v1.png";
import pbExample97 from "shared/assets/imagery/2952803_playbook19_v1.png";
import pbExample98 from "shared/assets/imagery/2952803_playbook20_v1.png";
import pbExample99 from "shared/assets/imagery/2952803_playbook21_v1.png";
import pbExample100 from "shared/assets/imagery/2952803_playbook22_v1.png";
import pbExample101 from "shared/assets/imagery/2952803_playbook23_v1.png";
import pbExample102 from "shared/assets/imagery/2952803_playbook24_v1.png";
import pbExample103 from "shared/assets/imagery/2952803_playbook25_v1.png";
import pbExample104 from "shared/assets/imagery/2952803_playbook26_v1.png";
import pbExample105 from "shared/assets/imagery/2952803_playbook27_v1.png";
import pbExample106 from "shared/assets/imagery/2952803_playbook28_v1.png";
import pbExample107 from "shared/assets/imagery/2952803_playbook29_v1.png";
import pbExample108 from "shared/assets/imagery/2952803_playbook30_v1.png";
import pbExample109 from "shared/assets/imagery/2952803_playbook31_v1.png";
import pbExample110 from "shared/assets/imagery/2952803_playbook32_v1.png";
import pbExample111 from "shared/assets/imagery/2952803_playbook33_v1.png";
import pbExample112 from "shared/assets/imagery/2952803_playbook34_v1.png";
import pbExample113 from "shared/assets/imagery/2952803_playbook35_v1.png";
import pbExample114 from "shared/assets/imagery/2952803_playbook36_v1.png";



const ImageryTemplate = () => {
  return (
    <Fragment>
      <Heading text="Imagery" />

      <Paragraph>
        <p>
          Selected photography needs to be as exciting and premium as the
          Betfair brand. It needs to inspire, whether through emotion or action.
        </p>
      </Paragraph>

      <Heading text="Racing" />

      <Gallery
        bigImagesOnly
        images={[
          {
            src: racingImage1,
            alt: "racing image 1",
            downloadUrl:
              "./downloads/5_Imagery/racing-imagery/RP_ID00343458.jpg",
          },
          {
            src: racingImage2,
            alt: "racing image 2",
            downloadUrl:
              "./downloads/5_Imagery/racing-imagery/RP_ID00367890.jpg",
          },
        ]}
      />

      <Heading text="Sports" />

      <Gallery
        bigImagesOnly
        images={[
          {
            src: sport1,
            alt: "sports image 1",
            downloadUrl: "./downloads/5_Imagery/sports-imagery/sport1_v1.png",
          },
          {
            src: sport2,
            alt: "sports image 2",
            downloadUrl: "./downloads/5_Imagery/sports-imagery/sport2_v1.png",
          },
          {
            src: sport3,
            alt: "sports image 3",
            downloadUrl: "./downloads/5_Imagery/sports-imagery/sport3_v1.png",
          },
          {
            src: sport4,
            alt: "sports image 4",
            downloadUrl: "./downloads/5_Imagery/sports-imagery/sport4_v1.png",
          },
        ]}
      />

      <Heading text="Casino - Product Led Assets" />

      <Gallery
        download
        bigImagesOnly
        images={[
          {
            src: casino1,
            alt: "casino image 1",
            downloadUrl:
              "./downloads/5_Imagery/casino-imagery/296892-Regular-Jackpot-Creative-02.png",
          },
          {
            src: casino2,
            alt: "casino image 2",
            downloadUrl:
              "./downloads/5_Imagery/casino-imagery/96892-Regular-Jackpot-Creative-01.png",
          },
          {
            src: casino13,
            alt: "casino image 13",
            downloadUrl:
              "./downloads/5_Imagery/ExtraHotJackpot.png",
          },
          {
            src: casino4,
            alt: "casino image 4",
            downloadUrl:
              "./downloads/5_Imagery/casino-imagery/3025154-Prize-Pinball-Plus-MMP-KV-2.png",
          },
          {
            src: casino14,
            alt: "casino image 14",
            downloadUrl:
              "./downloads/5_Imagery/casino-imagery/PPplusPhone visual.png",
          },
          {
            src: casino6,
            alt: "casino image 6",
            downloadUrl:
              "./downloads/5_Imagery/casino-imagery/STYWGeneric.png",
          },
        ]}
      />

<Heading text="Casino - Campaign Led Assets" />

<Gallery
  download
  bigImagesOnly
  images={[
    {
      src: casino7,
      alt: "casino image 7",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Betfair68338.png",
    },
    {
      src: casino8,
      alt: "casino image 8",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Brett_Neon_Sign_crates.png",
    },
    {
      src: casino9,
      alt: "casino image 9",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Betfair68603.png",
    },
    {
      src: casino10,
      alt: "casino image 10",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Betfair68235.png",
    },
    {
      src: casino11,
      alt: "casino image 11",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Betfair68629.png",
    },
    {
      src: casino12,
      alt: "casino image 12",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Betfair68335_72ppi.png",
    },
    {
      src: casino3,
      alt: "casino image 3",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Betfair_Casino_2022_PrizePinballJackpot_FullLength.png",
    },
    {
      src: casino5,
      alt: "casino image 5",
      downloadUrl:
        "./downloads/5_Imagery/casino-imagery/Picture-1.png",
    },
  ]}
/>

      <Heading text="Poker" />

      <Gallery
        download
        bigImagesOnly
        images={[
          {
            src: poker1,
            alt: "poker image 1",
            downloadUrl:
              "./downloads/5_Imagery/poker-imagery/2952803_poker1_v1.png",
          },
          {
            src: poker2,
            alt: "poker image 2",
            downloadUrl:
              "./downloads/5_Imagery/poker-imagery/2952803_poker2_v1.png",
          },
          {
            src: poker3,
            alt: "poker image 3",
            downloadUrl:
              "./downloads/5_Imagery/poker-imagery/2952803_poker3_v1.png",
          },
          {
            src: poker4,
            alt: "poker image 4",
            downloadUrl:
              "./downloads/5_Imagery/poker-imagery/2952803_poker4_v1.png",
          },
        ]}
      />

      <Heading text="Bingo" />

      <Gallery
        download
        bigImagesOnly
        images={[
          {
            src: bingo1,
            alt: "bingo image 1",
            downloadUrl:
              "./downloads/5_Imagery/bingo-imagery/2952803_bingo1_v1.png",
          },
          {
            src: bingo2,
            alt: "bingo image 2",
            downloadUrl:
              "./downloads/5_Imagery/bingo-imagery/2952803_bingo2_v1.png",
          },
          {
            src: bingo3,
            alt: "bingo image 3",
            downloadUrl:
              "./downloads/5_Imagery/bingo-imagery/2952803_bingo3_v1.png",
          },
          {
            src: bingo4,
            alt: "bingo image 4",
            downloadUrl:
              "./downloads/5_Imagery/bingo-imagery/2952803_bingo4_v1.png",
          },
        ]}
      />

      <Heading noMargin text="Playbook elements" />
      <Subheading text="Do's and don'ts" />

      <Paragraph>
        <p>
          Playbook elements draw attention to key details. They are the tips,
          the inside track, the memory aid scribbled on the Racing Post. They
          can interact scribbled on the Racing Post. They can interact with
          images, but they shouldn’t distract from the message. A little goes a
          long way.
        </p>
      </Paragraph>

      <Gallery
        download
        bigImagesOnly
        images={[
          {
            text: "<strong>Do</strong> use playbook elements to draw attention to key messages in the copy.",
            dos: true,
            src: dos,
            alt: "Dos image",
            downloadUrl: "./downloads/5_Imagery/dos&donts/2952803_dos_v1.jpg",
          },
          {
            text: "<strong>Don’t</strong> use playbook elements as decorations without a clear purpose.",
            donts: true,
            src: donts,
            alt: "Donts image",
            downloadUrl: "./downloads/5_Imagery/dos&donts/2952803_donts_v1.jpg",
          },
        ]}
      />

      <Gallery
        download
        bigImagesOnly
        images={[
          {
            text: "<strong>Do</strong> use the playbook to connect headline and subhead / offer.",
            dos: true,
            src: dos2,
            alt: "Dos image",
            downloadUrl: "./downloads/5_Imagery/dos&donts/2952803_dos2_v1.jpg",
          },
          {
            text: "<strong>Don’t</strong> use the playbook style to create standalone illustrations.",
            donts: true,
            src: donts2,
            alt: "Donts image",
            downloadUrl:
              "./downloads/5_Imagery/dos&donts/2952803_donts2_v1.jpg",
          },
        ]}
      />

      <Heading text="Playbook examples" />

      <Gallery
        download
        bigImagesOnly
        rowOf4
        bgColorBlack
        images={[
          {
            src: pbExample1,
            alt: "Playbook 1",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Exclamation-Mark-Yellow.png",
          },
          {
            src: pbExample2,
            alt: "Playbook 2",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-0-1-Yellow.png",
          },
          {
            src: pbExample3,
            alt: "Playbook 3",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-1-Yellow.png",
          },
          {
            src: pbExample4,
            alt: "Playbook 4",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-2-Yellow.png",
          },
          {
            src: pbExample5,
            alt: "Playbook 5",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-3-Yellow.png",
          },
          {
            src: pbExample6,
            alt: "Playbook 6",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-4-Yellow.png",
          },
          {
            src: pbExample7,
            alt: "Playbook 7",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-5-Yellow.png",
          },
          {
            src: pbExample8,
            alt: "Playbook 8",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-6-Yellow.png",
          },
          {
            src: pbExample9,
            alt: "Playbook 9",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-7-Yellow.png",
          },
          {
            src: pbExample10,
            alt: "Playbook 10",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-8-Yellow.png",
          },
          {
            src: pbExample11,
            alt: "Playbook 11",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-9-Yellow.png",
          },
          {
            src: pbExample12,
            alt: "Playbook 12",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-Line-Yellow.png",
          },
          {
            src: pbExample13,
            alt: "Playbook 13",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Asterix-Yellow.png",
          },
          {
            src: pbExample14,
            alt: "Playbook 14",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements--BF-Arrows-Yellow.png",
          },
          {
            src: pbExample15,
            alt: "Playbook 15",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Card-Yellow.png",
          },
          {
            src: pbExample16,
            alt: "Playbook 16",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Circle-1-Yellow.png",
          },
          {
            src: pbExample17,
            alt: "Playbook 17",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Circle-2-Yellow.png",
          },
          {
            src: pbExample18,
            alt: "Playbook 18",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Circle-3-Yellow.png",
          },
          {
            src: pbExample19,
            alt: "Playbook 19",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Corner-Flag-Yellow.png",
          },
          {
            src: pbExample20,
            alt: "Playbook 20",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Corner-Pitch-Yellow.png",
          },
          {
            src: pbExample21,
            alt: "Playbook 21",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Diamond-Yellow.png",
          },
          {
            src: pbExample22,
            alt: "Playbook 22",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Equation-Yellow.png",
          },
          {
            src: pbExample23,
            alt: "Playbook 23",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Flip-Chart-Yellow.png",
          },
          {
            src: pbExample24,
            alt: "Playbook 24",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Football-Picth-Yellow.png",
          },
          {
            src: pbExample25,
            alt: "Playbook 25",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Football-Yellow.png",
          },
          {
            src: pbExample26,
            alt: "Playbook 26",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Lock-Yellow.png",
          },
          {
            src: pbExample27,
            alt: "Playbook 27",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Multiple-Underline-2-Yellow.png",
          },
          {
            src: pbExample28,
            alt: "Playbook 28",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Multiple-Underline-Yellow.png",
          },
          {
            src: pbExample29,
            alt: "Playbook 29",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-OXO-Yellow.png",
          },
          {
            src: pbExample30,
            alt: "Playbook 30",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Parachute-Yellow.png",
          },
          {
            src: pbExample31,
            alt: "Playbook 31",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Piggy-Bank-Yellow.png",
          },
          {
            src: pbExample32,
            alt: "Playbook 32",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Single-Line-Yellow.png",
          },
          {
            src: pbExample33,
            alt: "Playbook 33",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Star-Yellow.png",
          },
          {
            src: pbExample34,
            alt: "Playbook 34",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Strategy-Yellow.png",
          },
          {
            src: pbExample35,
            alt: "Playbook 35",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Tick-Yellow.png",
          },
          {
            src: pbExample36,
            alt: "Playbook 36",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Umbrella-Yellow.png",
          },
          {
            src: pbExample37,
            alt: "Playbook 37",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Wavy-Line-2-Yellow.png",
          },
          {
            src: pbExample38,
            alt: "Playbook 38",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Wavy-Line-Yellow.png",
          },
          {
            src: pbExample39,
            alt: "Playbook 39",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Whistle-Yellow.png",
          },
        ]}
      />
      <Gallery
        download
        bigImagesOnly
        rowOf4
        bgColorBlack
        images={[
          {
            src: pbExample79,
            alt: "Playbook 79",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook1_v1.png",
          },
          {
            src: pbExample80,
            alt: "Playbook 80",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook2_v1.png",
          },
          {
            src: pbExample81,
            alt: "Playbook 81",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook3_v1.png",
          },
          {
            src: pbExample82,
            alt: "Playbook 82",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook4_v1.png",
          },
          {
            src: pbExample83,
            alt: "Playbook 83",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook5_v1.png",
          },
          {
            src: pbExample84,
            alt: "Playbook 84",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook6_v1.png",
          },
          {
            src: pbExample85,
            alt: "Playbook 85",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook7_v1.png",
          },
          {
            src: pbExample86,
            alt: "Playbook 86",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook8_v1.png",
          },
          {
            src: pbExample87,
            alt: "Playbook 87",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook9_v1.png",
          },
          {
            src: pbExample88,
            alt: "Playbook 88",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook10_v1.png",
          },
          {
            src: pbExample89,
            alt: "Playbook 89",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook11_v1.png",
          },
          {
            src: pbExample90,
            alt: "Playbook 90",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook12_v1.png",
          },
          {
            src: pbExample91,
            alt: "Playbook 91",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook13_v1.png",
          },
          {
            src: pbExample92,
            alt: "Playbook 92",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook14_v1.png",
          },
          {
            src: pbExample93,
            alt: "Playbook 93",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook15_v1.png",
          },
          {
            src: pbExample94,
            alt: "Playbook 94",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook16_v1.png",
          },
          {
            src: pbExample95,
            alt: "Playbook 95",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook17_v1.png",
          },
          {
            src: pbExample96,
            alt: "Playbook 96",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook18_v1.png",
          },
          {
            src: pbExample97,
            alt: "Playbook 97",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook19_v1.png",
          },
          {
            src: pbExample98,
            alt: "Playbook 98",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook20_v1.png",
          },
          {
            src: pbExample98,
            alt: "Playbook 98",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook21_v1.png",
          },
          {
            src: pbExample99,
            alt: "Playbook 99",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook22_v1.png",
          },
          {
            src: pbExample100,
            alt: "Playbook 100",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook23_v1.png",
          },
          {
            src: pbExample101,
            alt: "Playbook 101",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook24_v1.png",
          },
          {
            src: pbExample102,
            alt: "Playbook 102",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook25_v1.png",
          },
          {
            src: pbExample103,
            alt: "Playbook 103",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook26_v1.png",
          },
          {
            src: pbExample104,
            alt: "Playbook 104",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook27_v1.png",
          },
          {
            src: pbExample105,
            alt: "Playbook 105",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook28_v1.png",
          },
          {
            src: pbExample106,
            alt: "Playbook 106",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook29_v1.png",
          },
          {
            src: pbExample107,
            alt: "Playbook 107",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook30_v1.png",
          },
          {
            src: pbExample108,
            alt: "Playbook 108",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook31_v1.png",
          },
          {
            src: pbExample109,
            alt: "Playbook 109",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook32_v1.png",
          },
          {
            src: pbExample110,
            alt: "Playbook 110",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook33_v1.png",
          },
          {
            src: pbExample111,
            alt: "Playbook 111",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook34_v1.png",
          },
          {
            src: pbExample112,
            alt: "Playbook 112",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook35_v1.png",
          },
          {
            src: pbExample113,
            alt: "Playbook 113",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playboo36_v1.png",
          },
          {
            src: pbExample114,
            alt: "Playbook 114",
            downloadUrl:
              "./downloads/5_Imagery/playbook/2952803_playbook37_v1.png",
          },
         
        ]}
      />
            <Gallery
        download
        bigImagesOnly
        rowOf4
        bgColorWhite
        images={[
          {
            src: pbExample40,
            alt: "Playbook 40",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Exclamation-Mark-Black.png",
          },
          {
            src: pbExample41,
            alt: "Playbook 41",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-0-1-Black.png",
          },
          {
            src: pbExample42,
            alt: "Playbook 42",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-1-Black.png",
          },
          {
            src: pbExample43,
            alt: "Playbook 43",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-2-Black.png",
          },
          {
            src: pbExample44,
            alt: "Playbook 44",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-3-Black.png",
          },
          {
            src: pbExample45,
            alt: "Playbook 45",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-4-Black.png",
          },
          {
            src: pbExample46,
            alt: "Playbook 46",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-5-Black.png",
          },
          {
            src: pbExample47,
            alt: "Playbook 47",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-6-Black.png",
          },
          {
            src: pbExample48,
            alt: "Playbook 48",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-7-Black.png",
          },
          {
            src: pbExample49,
            alt: "Playbook 49",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-8-Black.png",
          },
          {
            src: pbExample50,
            alt: "Playbook 50",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-9-Black.png",
          },
          {
            src: pbExample51,
            alt: "Playbook 51",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Arrow-Line-Black.png",
          },
          {
            src: pbExample52,
            alt: "Playbook 52",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Asterix-Black.png",
          },
          {
            src: pbExample53,
            alt: "Playbook 53",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements--BF-Arrows-Black.png",
          },
          {
            src: pbExample54,
            alt: "Playbook 54",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Card-Black.png",
          },
          {
            src: pbExample55,
            alt: "Playbook 55",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Circle-1-Black.png",
          },
          {
            src: pbExample56,
            alt: "Playbook 56",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Circle-2-Black.png",
          },
          {
            src: pbExample57,
            alt: "Playbook 57",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Circle-3-Black.png",
          },
          {
            src: pbExample58,
            alt: "Playbook 58",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Corner-Flag-Black.png",
          },
          {
            src: pbExample59,
            alt: "Playbook 59",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Corner-Pitch-Black.png",
          },
          {
            src: pbExample60,
            alt: "Playbook 60",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Diamond-Black.png",
          },
          {
            src: pbExample61,
            alt: "Playbook 61",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Equation-Black.png",
          },
          {
            src: pbExample62,
            alt: "Playbook 62",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Flip-Chart-Black.png",
          },
          {
            src: pbExample63,
            alt: "Playbook 63",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Football-Picth-Black.png",
          },
          {
            src: pbExample64,
            alt: "Playbook 64",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Football-Black.png",
          },
          {
            src: pbExample65,
            alt: "Playbook 65",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Lock-Black.png",
          },
          {
            src: pbExample66,
            alt: "Playbook 66",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Multiple-Underline-2-Black.png",
          },
          {
            src: pbExample67,
            alt: "Playbook 67",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Multiple-Underline-Black.png",
          },
          {
            src: pbExample68,
            alt: "Playbook 68",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-OXO-Black.png",
          },
          {
            src: pbExample69,
            alt: "Playbook 69",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Parachute-Black.png",
          },
          {
            src: pbExample70,
            alt: "Playbook 70",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Piggy-Bank-Black.png",
          },
          {
            src: pbExample71,
            alt: "Playbook 71",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Single-Line-Black.png",
          },
          {
            src: pbExample72,
            alt: "Playbook 72",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Star-Black.png",
          },
          {
            src: pbExample73,
            alt: "Playbook 73",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Strategy-Black.png",
          },
          {
            src: pbExample74,
            alt: "Playbook 74",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Tick-Black.png",
          },
          {
            src: pbExample75,
            alt: "Playbook 75",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Umbrella-Black.png",
          },
          {
            src: pbExample76,
            alt: "Playbook 76",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Wavy-Line-2-Black.png",
          },
          {
            src: pbExample77,
            alt: "Playbook 77",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Wavy-Line-Black.png",
          },
          {
            src: pbExample78,
            alt: "Playbook 78",
            downloadUrl:
              "./downloads/5_Imagery/playbook-colour/Playbook-Elements-Whistle-Black.png",
          },
        ]}
      />

      <DownloadButton
        title="Download Playbook elements"
        href="./downloads/5_Imagery/playbook-colour/PlaybookElementsBlackYellow.zip"
      />
    </Fragment>
  );
};

export default ImageryTemplate;
