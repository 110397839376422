import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './Wrapper.module.scss';
let cx = classNames.bind(styles);

const Wrapper = forwardRef(({ children, className }, ref) => {
    const wrapperClasses = cx({
        wrapper: true,
        [className]: className
    });
    return (
        <div className={wrapperClasses} ref={ref}>{children}</div>
    )
})

Wrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
}

export default Wrapper;