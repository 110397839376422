import React from "react";

import { useCurrentlyActiveSection } from "providers/CurrentlyActiveSection";

import TopSection from "../TopSection/TopSection";
import Wrapper from "../Wrapper/Wrapper";
import AsideNav from "../AsideNav/AsideNav";
import Footer from "../Footer/Footer";
import ScrollUpButton from "../ScrollUpButton/ScrollUpButton";

import LogosTemplate from "templates/LogosTemplate";
import BrandMarkTemplate from "templates/BrandMarkTemplate";
import PlaybooksTemplate from "templates/PlaybooksTemplate";
import SaferGamblingTemplate from "templates/SaferGamblingTemplate";
import ColoursTemplate from "templates/ColoursTemplate";
import FontTemplate from "templates/FontTemplate";
import ImageryTemplate from "templates/ImageryTemplate";
import LayoutsTemplate from "templates/LayoutsTemplate";
import SonicBrandingTemplate from "templates/SonicBrandingTemplate";
import DownloadsTemplate from "templates/DownloadsTemplate/DownloadsTemplate";
import StrategyTemplate from "templates/StrategyTemplate";
import PeopleBrandTemplate from "templates/PeopleBrandTemplate";
import PptTemplate from "templates/PptTemplate";

import styles from "./MainLayout.module.scss";

const MainLayout = () => {
  const { currentlyActiveSectionId } = useCurrentlyActiveSection();
  return (
    <main className={styles.layout} id="main-layout">
      <TopSection title="Betfair Brand Hub" />
      <Wrapper className={styles.wrapper}>
        <div className={styles.content}>
          <AsideNav
            activeItem={currentlyActiveSectionId}
            items={[
              {
                accordionTitle: "Betfair Brand Codes",
                list: [
                  {
                    title: "Logos",
                    id: "logos",
                  },
                  {
                    title: "Brand Mark",
                    id: "brand-mark",
                  },
                  {
                    title: "Colours",
                    id: "colours",
                  },
                  {
                    title: "Font",
                    id: "font",
                  },
                  // {
                  //   title: "Sonic Branding",
                  //   id: "sonic-branding",
                  // },
                ],
              },
              {
                accordionTitle: "Betfair in Practice",
                list: [
                  {
                    title: "Imagery",
                    id: "imagery",
                  },
                  {
                    title: "Layouts",
                    id: "layouts",
                  },
                  {
                    title: "Safer Gambling",
                    id: "safer-gambling",
                  },
                  {
                    title: "Playbooks",
                    id: "playbooks",
                  },
                ],
              },
              {
                accordionTitle: "PowerPoint assets",
                list: [
                  {
                    title: "Betfair PPT template",
                    id: "ppt",
                  },
                  {
                    title: "PPT imagery ",
                    id: "ppt",
                  },
                ],
              },
              {
                accordionTitle: "Betfair Strategy",
                list: [
                  {
                    title: "Betfair Fights Back",
                    id: "strategy",
                  },
                  {
                    title: "Brand Strategy",
                    id: "strategy",
                  },
                  {
                    title: "Safer Gambling Strategy",
                    id: "strategy",
                  },
                ],
              },
              {
                accordionTitle: "Betfair People Brand",
                list: [
                  {
                    title: "People Playbook",
                    id: "people-brand",
                  },
                  {
                    title: "Job Ad",
                    id: "people-brand",
                  },
                ],
              },
              {
                accordionTitle: "Downloads",
                list: [
                  {
                    title: "All Downloads",
                    id: "downloads",
                  },
                ],
              },
            ]}
          />
          <div className={styles.section} id="mainSection">
            {currentlyActiveSectionId === "logos" && <LogosTemplate />}
            {currentlyActiveSectionId === "brand-mark" && <BrandMarkTemplate />}
            {currentlyActiveSectionId === "colours" && <ColoursTemplate />}
            {currentlyActiveSectionId === "font" && <FontTemplate />}
            {currentlyActiveSectionId === "imagery" && <ImageryTemplate />}
            {currentlyActiveSectionId === "layouts" && <LayoutsTemplate />}
            {/* {currentlyActiveSectionId === "sonic-branding" && (
              <SonicBrandingTemplate />
            )} */}
            {currentlyActiveSectionId === "playbooks" && <PlaybooksTemplate />}
            {currentlyActiveSectionId === "safer-gambling" && (
              <SaferGamblingTemplate />
            )}
            {currentlyActiveSectionId === "downloads" && <DownloadsTemplate />}
            {currentlyActiveSectionId === "strategy" && <StrategyTemplate />}
            {currentlyActiveSectionId === "people-brand" && (
              <PeopleBrandTemplate />
            )}
            {currentlyActiveSectionId === "ppt" && <PptTemplate />}
          </div>
        </div>
        <ScrollUpButton />
      </Wrapper>
      <Footer />
    </main>
  );
};

export default MainLayout;
