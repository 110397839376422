import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import classNames from 'classnames/bind';
import styles from './BannerBuilder.module.scss';
let cx = classNames.bind(styles);


const BannerBuilder = ({ items }) => {
    const [activeIndexes, setActiveIndexes] = useState({});

    useEffect(() => {
        items.forEach((item, index) => {
            setActiveIndexes((prevState) => ({
                ...prevState,
                [`activeIndex${index}`]: item.isActive
            }))
        })
        return () => {
            setActiveIndexes({});
        }
    }, []);

    const clickHandler = (el) => {
        const index = parseInt(el.target.dataset.index);
        setActiveIndexes((prevState) => ({
            ...prevState,
            [`activeIndex${index}`]: !activeIndexes[`activeIndex${index}`]
        }));

    }
    return (
        <div className={styles.container}>
            <div className={styles.buttons}>
                {items.map((item, index) => {
                    const buttonClasses = cx({
                        button: true,
                        active: activeIndexes[`activeIndex${index}`]
                    });
                    return (
                        <button
                            type="button"
                            className={buttonClasses}
                            key={`title-${item.src}`}
                            data-index={index}
                            onClick={clickHandler}>
                            <span className={styles.checkmark}>
                                <Icon icon="check1" className={styles.icon} />
                            </span>
                            {item.title}
                        </button>
                    )
                })}
            </div>
            <div className={styles.images}>
                {items.map((item, index) => {
                    const imageClasses = cx({
                        image: true,
                        active: activeIndexes[`activeIndex${index}`]
                    });
                    return (
                        <img src={item.src} alt={item.alt} className={imageClasses} key={`image-${item.src}`} />
                    )
                })}
            </div>
        </div>
    )
}

BannerBuilder.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        src: PropTypes.string,
        alt: PropTypes.string,
        isActive: PropTypes.bool
    }))
}

export default BannerBuilder;