import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useMenuVisibility } from "providers/MenuVisibility";
import classNames from "classnames/bind";
import styles from "./AsideNav.module.scss";
import ToggledElement from "shared/components/ToggledElement/ToggledElement";

let cx = classNames.bind(styles);

const AsideNav = ({ items, activeItem }) => {
  const { isMenuVisible, setIsMenuVisible } = useMenuVisibility();
  const navRef = useRef();

  const handleMenuVisability = (isVisible) => {
    setIsMenuVisible(isVisible);
  };

  const navClasses = cx({
    nav: true,
    active: isMenuVisible,
  });

  return (
    <nav className={navClasses} ref={navRef}>
      {items.map((item, index) => (
        <ToggledElement
          key={`${index}-${item.accordionTitle}`}
          item={item}
          activeItem={activeItem}
          isMenuVisible={handleMenuVisability}
        />
      ))}
    </nav>
  );
};

AsideNav.propTypes = {
  activeItem: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      accordionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          id: PropTypes.string,
        })
      ),
    })
  ),
};

export default AsideNav;
