import React from "react";

import styles from "./LayoutContainer.module.scss";
import Heading from "shared/components/Heading/Heading";
import Subheading from "shared/components/Subheading/Subheading";
import Image from "shared/components/Image/Image";
import Paragraph from "shared/components/Paragraphs/Paragraph";

const LayoutContainer = ({ items, noMargin = false }) => {
  return (
    <div className={styles.container}>
      {items?.map((item, i) => (
        <div className={styles.wrapper} key={`${i} - ${item.title}`}>
          <div className={styles.content}>
            <Heading smallLineHeight text={item.title} />
            <Subheading marginXL text={item.subtitle} />
            <Paragraph>
              <p>{item.text}</p>
            </Paragraph>
          </div>
          <Image noMargin={noMargin} src={item.imageSrc} alt={item.imageAlt} />
        </div>
      ))}
    </div>
  );
};

export default LayoutContainer;
