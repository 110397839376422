import React from "react";
import PropTypes from 'prop-types';
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";

const Icon = ({ icon, className }) => {
    return (
        <IcomoonReact
            className={className}
            iconSet={iconSet}
            icon={icon}
        />
    );
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default Icon;