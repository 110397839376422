import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Subheading from "shared/components/Subheading/Subheading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import LayoutContainer from "shared/components/LayoutContainer/LayoutContainer";
import BannerBuilder from "shared/components/BannerBuilder/BannerBuilder";

import campaignText from "shared/assets/campaign-layout/baneer1_copy_v1.png";
import campaignImage from "shared/assets/campaign-layout/baneer1_image_v1.png";
import campaignLogo from "shared/assets/campaign-layout/baneer1_logo_v1.png";
import campaignPlaybook from "shared/assets/campaign-layout/baneer1_playbook_v1.png";
import campaignTerms from "shared/assets/campaign-layout/baneer1_terms_v1.png";
import campaignBg from "shared/assets/campaign-layout/banner1_bg_v1.jpg";
import campaignBlackband from "shared/assets/campaign-layout/banner1_blackband_v1.png";

import casinoBackground from "shared/assets/casino-layout/banner2_bg_v1.jpg";
import casinoLogo from "shared/assets/casino-layout/banner2_logo_v1.png";
import casinoHeroImage from "shared/assets/casino-layout/banner2_image_v1.png";
import casinoText from "shared/assets/casino-layout/banner2_copy_v1.png";
import casinoPlaybook from "shared/assets/campaign-layout/baneer1_playbook_v1.png";
import casinoTc from "shared/assets/casino-layout/banner2_terms_v1.png";

import banner1 from "shared/assets/layout-banners/bannerOne.jpg";
import banner2 from "shared/assets/layout-banners/bannerTwo.jpg";
import banner3 from "shared/assets/layout-banners/bannerThree.jpg";

const LayoutsTemplate = () => {
  return (
    <Fragment>
      <Heading text="Layouts" />

      <Paragraph>
        <p>
          We use a full suite of media to advertise. So in order to keep our
          communications looking consistent across all these touchpoints and
          their various sizes and nuances, we've developed some key elements to
          include in all formats.
        </p>
      </Paragraph>

      <Heading noMargin text="Campaign" />
      <Subheading text="Yellow background layout" />

      <BannerBuilder
        items={[
          {
            title: "Add yellow background",
            src: campaignBg,
            alt: "background",
            isActive: false,
          },
          {
            title: "Add playbook elements",
            src: campaignPlaybook,
            alt: "Campaign Playbook",
            isActive: false,
          },
          {
            title: "Add text in Noto Sans",
            src: campaignText,
            alt: "Text",
            isActive: false,
          },
          {
            title: "Add hero image of brand ambassador",
            src: campaignImage,
            alt: "Hero image",
            isActive: false,
          },
          {
            title: "Add black band at the bottom",
            src: campaignBlackband,
            alt: "black band",
            isActive: false,
          },
          {
            title: "Add T&Cs",
            src: campaignTerms,
            alt: "T&Cs",
            isActive: false,
          },
          {
            title: "Add logo on bottom right",
            src: campaignLogo,
            alt: "logo",
            isActive: false,
          },
        ]}
      />

      <Heading noMargin text="Casino" />
      <Subheading text="Using a black background" />

      <Paragraph>
        <p
          dangerouslySetInnerHTML={{
            __html: `For Casino we can flip the script when we need to – the background
          becomes predominantly black to allow our neon assets to stand out
          against it. We bring extra yellow into these layouts through the
          playbook elements. 
          <br/>
          <br/>
          A yellow background remains the preferred option in Casino applications that don’t 
          feature neon graphics. Playbook elements can be yellow or white on black backgrounds, 
          but all playbook elements in any one composition should be the same colour.`,
          }}
        />
      </Paragraph>

      <BannerBuilder
        items={[
          {
            title: "Add background",
            src: casinoBackground,
            alt: "background",
            isActive: false,
          },
          {
            title:
              "Add text in Noto Sans (yellow can be used to highlight key words)",
            src: casinoText,
            alt: "black yellow marks",
            isActive: false,
          },
          {
            title: "Add playbook elements",
            src: casinoPlaybook,
            alt: "logo",
            isActive: false,
          },
          {
            title: "Add image",
            src: casinoHeroImage,
            alt: "hero image",
            isActive: false,
          },

          {
            title: "Add Betfair Casino logo in black or a yellow bar",
            src: casinoLogo,
            alt: "headline",
            isActive: false,
          },
          {
            title: "Add T&Cs",
            src: casinoTc,
            alt: "sg logo",
            isActive: false,
          },
        ]}
      />

      <LayoutContainer
        items={[
          {
            title: "BAU layout examples",
            subtitle: "Football",
            imageSrc: banner1,
            imageAlt: "Banner 1",
            text: `As with hero images from campaigns, the goal with
            everyday creative is to maximise yellow. When a
            full-bleed image needs to be accommodated the
            playbook elements can be used to increase Betfair
            branding / provide ownership of stock.`,
          },
          {
            title: "Full-bleed images",
            subtitle: "Yellow text box alternative",
            imageSrc: banner2,
            imageAlt: "Banner 2",
            text: `Full bleed images can be accommodated,
            but their use should be limited to necessary
            instances. We want to maximise our use of
            yellow whenever possible.`,
          },
        ]}
      />

      <LayoutContainer
        noMargin
        items={[
          {
            title: "Safer Gambling",
            subtitle: "BAU",
            imageSrc: banner3,
            imageAlt: "Banner 3",
            text: `For everyday creative we pair the playbook with the existing Safer Gambling icon style
            to promote the tools in a simple but onbrand way.`,
          },
        ]}
      />
    </Fragment>
  );
};

export default LayoutsTemplate;
