import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Subheading from "shared/components/Subheading/Subheading";
import Gallery from "shared/components/Gallery/Gallery";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import Image from "shared/components/Image/Image";

import betfairPowerpointTemplate from "shared/assets/image-2023-03-02-11-26-36-350.png";
import betfairHowToGuide from "shared/assets/image-2023-03-02-11-31-11-217.png";
import brettflairdeck from "shared/assets/playbook-page/casino-ppt-deck.jpg";

import footballImage1 from "shared/assets/powerpoint/football/2952803_sport1_v1.png";
import footballImage2 from "shared/assets/powerpoint/football/2952803_sport2_v1.png";
import footballImage3 from "shared/assets/powerpoint/football/2952803_sport3_v1.png";
import footballImage4 from "shared/assets/powerpoint/football/2952803_sport4_v1.png";

import casinoImage1 from "shared/assets/powerpoint/casino/96892-Regular-Jackpot-Creative-01-min.png";
import casinoImage2 from "shared/assets/powerpoint/casino/96892-Regular-Jackpot-Creative-02-min.png";
import casinoImage3 from "shared/assets/powerpoint/casino/2852803_casino3_v1.png";
import casinoImage4 from "shared/assets/powerpoint/casino/2852803_casino4_v1.png";

import racingImage1 from "shared/assets/powerpoint/racing/2852803_racing1_v1.png";
import racingImage2 from "shared/assets/powerpoint/racing/2852803_racing2_v1.png";
import racingImage3 from "shared/assets/powerpoint/racing/2852803_racing3_v1.png";
import racingImage4 from "shared/assets/powerpoint/racing/2852803_racing4_v1.png";

const PptTemplate = () => {
  return (
    <Fragment>
      <Heading noMargin text="PowerPoint Assets" />
      <Subheading marginXXL text="Betfair Powerpoint Template" />
      <Image
        src={betfairPowerpointTemplate}
        alt="Betfair Powerpoint Template"
        mediumMargin
      />
      <DownloadButton
        title="Download Betfair Powerpoint Template"
        href="./downloads/12_Templates/Betfair Template v0.1.pptx"
      />

      <Heading noMargin text="Betfair Template" />
      <Subheading marginXXL text="How to guide" />
      <Image
        src={betfairHowToGuide}
        alt="Betfair Template How to guide"
        mediumMargin
      />
      <DownloadButton
        title="Download Betfair Template: How to guide"
        href="./downloads/12_Templates/Betfair Template v0.1 How-to guide.pptx"
      />

<Heading text="Betfair Gaming PowerPoint Template" />

<Image src={brettflairdeck} alt="Brett Flair Betfair Casino"  mediumMargin/>

<DownloadButton
  title="Download Brett PPT Deck"
  href="./downloads/8_Playbooks for Microsite/BF Gaming - Brett Flair Deck Final.pptm"
/>
      <Heading text="Football" />

      <Gallery
        bigImagesOnly
        rowOf2
        images={[
          {
            src: footballImage1,
            alt: "football image 1",
            downloadUrl:
              "./downloads/13_PowerPoint/football/2952803_sport1_v1.png",
          },
          {
            src: footballImage2,
            alt: "football image 2",
            downloadUrl:
              "./downloads/13_PowerPoint/football/2952803_sport2_v1.png",
          },
          {
            src: footballImage3,
            alt: "football image 3",
            downloadUrl:
              "./downloads/13_PowerPoint/football/2952803_sport3_v1.png",
          },
          {
            src: footballImage4,
            alt: "football image 4",
            downloadUrl:
              "./downloads/13_PowerPoint/football/2952803_sport4_v1.png",
          },
        ]}
      />

      <Heading text="Casino" />

      <Gallery
        bigImagesOnly
        rowOf2
        images={[
          {
            src: casinoImage1,
            alt: "casino image 1",
            downloadUrl:
              "./downloads/13_PowerPoint/casino/96892-Regular-Jackpot-Creative-01.png",
          },
          {
            src: casinoImage2,
            alt: "casino image 2",
            downloadUrl:
              "./downloads/13_PowerPoint/casino/96892-Regular-Jackpot-Creative-02.png",
          },
          {
            src: casinoImage3,
            alt: "casino image 3",
            downloadUrl: "./downloads/13_PowerPoint/casino/Betfair68338.png",
          },
          {
            src: casinoImage4,
            alt: "casino image 4",
            downloadUrl: "./downloads/13_PowerPoint/casino/Betfair68603.png",
          },
        ]}
      />

      <Heading text="Racing" />

      <Gallery
        bigImagesOnly
        images={[
          {
            src: racingImage1,
            alt: "racing image 1",
            downloadUrl: "./downloads/13_PowerPoint/racing/2852803_racing1_v1.png",
          },
          {
            src: racingImage2,
            alt: "racing image 2",
            downloadUrl:
              "./downloads/13_PowerPoint/racing/2852803_racing2_v1.png",
          },
          {
            src: racingImage3,
            alt: "racing image 3",
            downloadUrl: "./downloads/13_PowerPoint/racing/2852803_racing3_v1.png",
          },
          {
            src: racingImage4,
            alt: "racing image 4",
            downloadUrl: "./downloads/13_PowerPoint/racing/2852803_racing4_v1.png",
          },
        ]}
      />
    </Fragment>
  );
};

export default PptTemplate;
