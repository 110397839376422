import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './Soundwave.module.scss';
let cx = classNames.bind(styles);

const Soundwave = ({ active }) => {
    const soundwaveClasses = cx({
        soundwave: true,
        active: active
    });

    const bars = [];

    for (let i = 1; i <= 26; i++) {
        bars.push(
            <div className={styles.bar} key={i}></div>
        )
    }
    return (
        <div className={styles.container}>
            <div className={soundwaveClasses}>
                {bars}
            </div>
        </div>
    )
}

Soundwave.propTypes = {
    active: PropTypes.bool
}

export default Soundwave;