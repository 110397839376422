import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import Gameplan from "shared/components/Gameplan/Gameplan";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";

import peoplePlaybook from "shared/assets/people-brand-page/people-playbook.jpg";
import betfairJobAd from "shared/assets/people-brand-page/betfair-job-ad.jpg";
import Image from "shared/components/Image/Image";

const PeopleBrandTemplate = () => {
  return (
    <Fragment>
      <Heading smallLineHeight text="Betfair People Brand" />

      <Paragraph>
        <p>
          The Betfair People brand outlines who we are as a business internally,
          what our culture looks like and what our Gameplan is. Here you can
          find guidelines to help you communicate in the People Brand tone of
          voice plus useful tools to help attract talent, on-board new starters
          and engagement tips & tricks.
        </p>
      </Paragraph>

      <Heading text="Betfair People Playbook" />
      <Image mediumMargin src={peoplePlaybook} alt="Betfair people Playbook" />
      <DownloadButton
        title="Download Betfair People Playbook"
        href="./downloads/11_People_Brand/Betfair _People_ Playbook.pdf"
      />

      <Heading text="The Betfair Job Ad" />
      <Image mediumMargin src={betfairJobAd} alt="Betfair job ad" />
      <DownloadButton
        title="Download Betfair Job Ad"
        href="./downloads/11_People_Brand/Betfair Job ad template.pdf"
      />

      <Heading text="Gameplan" />
      <Gameplan />
      <DownloadButton
        title="Download Gameplan"
        href="./downloads/11_People_Brand/gameplan.zip"
      />
    </Fragment>
  );
};

export default PeopleBrandTemplate;
