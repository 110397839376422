import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import ColorPalette from "shared/components/ColorPalette/ColorPalette";

const ColoursTemplate = () => {
  return (
    <Fragment>
      <Heading text="Primary colour palette" />

      <Paragraph>
        <p>
          A key part of the Betfair look & feel is its highly recognisable
          colour palette. It’s simple yet bold and versatile. Note that this is
          the core brand palette, and that certain specific rules apply to
          several of the Betfair products.
        </p>
      </Paragraph>

      <ColorPalette
        palettes={[
          {
            title: "Yellow",
            backgroundColor: "#FFB80C",
            backgroundColorRgba: "R255 G184 B12",
          },
          {
            title: "Black",
            backgroundColor: "#000000",
            backgroundColorRgba: "R0 G0 B0",
          },
          {
            title: "White",
            backgroundColor: "#FFFFFF",
            backgroundColorRgba: "R255 G255 B255",
          },
        ]}
        btnTitle="Download Brand colour pallete"
        btnHref="./downloads/3_Colours for Microsite/Betfair_Core_Colours.zip"
      />

      <Heading noMargin text="Product colour palette" />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
          flexWrap: "wrap",
        }}
      >
        <ColorPalette
          noMargin
          palettes={[
            {
              title: "Yellow",
              backgroundColor: "#FFB80C",
              backgroundColorRgba: "R255 G184 B12",
            },
            {
              title: "Black",
              backgroundColor: "#000000",
              backgroundColorRgba: "R0 G0 B0",
            },
            {
              title: "Poker Blue",
              backgroundColor: "#0060F0",
              backgroundColorRgba: "R0 G96 B240",
            },
          ]}
          btnTitle="Download Poker colour pallete"
          btnHref="./downloads/3_Colours for Microsite/Betfair_Poker_Colours.zip"
          subheading="Poker colours"
        />
        <ColorPalette
          palettes={[
            {
              title: "Yellow",
              backgroundColor: "#FFB80C",
              backgroundColorRgba: "R255 G184 B12",
            },
            {
              title: "Black",
              backgroundColor: "#000000",
              backgroundColorRgba: "R0 G0 B0",
            },
            {
              title: "Bingo Pink",
              backgroundColor: "#CB2072",
              backgroundColorRgba: "R203 G32 B114",
            },
          ]}
          btnTitle="Download Bingo colour pallete"
          btnHref="./downloads/3_Colours for Microsite/Betfair_Bingo_Colours.zip"
          subheading="Bingo colours"
        />
      </div>
    </Fragment>
  );
};

export default ColoursTemplate;
