import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import SlideToggle from "react-slide-toggle";

import styles from './Accordion.module.scss';

const Accordion = ({ children, title }) => {
    return (
        <div className={styles.accordion}>
            <SlideToggle
                duration={300}
                collapsed={true}
            >
                {({ toggle, setCollapsibleElement }) => (
                    <Fragment>
                        <div className={styles.content} ref={setCollapsibleElement}>
                            {children}
                        </div>
                        <button type="button" className={styles.button} onClick={toggle}>
                            {title}
                            <Icon icon="arrow-down" className={styles.icon} />
                        </button>
                    </Fragment>
                )}
            </SlideToggle>
        </div>
    )
}

Accordion.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string
}

export default Accordion;
