import React from "react";
import Image from "shared/components/Image/Image";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import { addClass } from "utils/addClass";

import styles from "./LogoSectionItem.module.scss";

const LogoSectionItem = ({ img, imgAlt, text, red }) => {
  return (
    <div className={styles.container}>
      <Image
        className={addClass(styles.img, red ? styles.absolute : "")}
        noMargin
        src={img}
        alt={imgAlt}
      />
      <Paragraph
        red={red}
        className={addClass(styles.text, red ? styles.absolute : "")}
      >
        <p>{text}</p>
      </Paragraph>
    </div>
  );
};

export default LogoSectionItem;
