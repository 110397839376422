import { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

const useScrollToSection = () => {
    const tl = useRef();

    const scrollToSection = (section, duration = 0, noOffset = false) => {
        const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
        const topSectionHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--top-section-height'));

        tl.current = gsap.timeline().to(window, { duration: 0.5, scrollTo: { y: section, offsetY: noOffset ? 0 : `${headerHeight + topSectionHeight}` } });
    };

    return { scrollToSection };
};

export default useScrollToSection;
