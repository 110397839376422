import React from "react";

import PropTypes from "prop-types";
import Image from "../Image/Image";

import styles from "./ImageAndInfo.module.scss";

const ImageAndInfo = ({
  src,
  alt,
  items,
  title,
  background = "#FFB80C",
  textColor = "#1e1e1e",
}) => {
  return (
    <div className={styles.container}>
      <Image className={styles.img} src={src} alt={alt} noMargin />
      <div
        className={styles.info}
        style={{ backgroundColor: background, color: textColor }}
      >
        <div className={styles.content}>
          {title}
          {items.map((item) => {
            return (
              <p key={item.text} className={styles.text}>
                {item.text}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ImageAndInfo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  background: PropTypes.string,
  textColor: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
};

export default ImageAndInfo;
