import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Intro from "shared/components/Intro/Intro";
import MainLayout from "shared/components/MainLayout/MainLayout";

const Homepage = () => {
  const hasHash = window.location.hash ? true : false;
  // const [introVisible, setIntroVisible] = useState(false);

  const getToday = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    today = mm + dd + yyyy;

    return +today;
  };

  // useEffect(() => {
  //     const today = getToday();

  //     const introLastSeen = localStorage.getItem('introSeenOn') || null;

  //     if (today !== +introLastSeen) {
  //         setIntroVisible(true);
  //         localStorage.setItem('introSeenOn', today);
  //     } else {
  //         setIntroVisible(false);
  //     }
  //     return () => {
  //         setIntroVisible(true);
  //     };
  // }, []);

  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {!hasHash && (
        <Fragment>
          <Intro />
          <MainLayout />
        </Fragment>
      )}
      {hasHash && <MainLayout />}
    </Fragment>
  );
};

export default Homepage;
