import React, { useEffect, useState } from "react";
import Image from "shared/components/Image/Image";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import useWindowResize from "shared/hooks/useWindowResize";
import Heading from "shared/components/Heading/Heading";

import styles from "./BannerTextSection.module.scss";
import { addClass } from "utils/addClass";

const BannerTextSection = ({
  desktopBanner,
  mobileBanner,
  asideText,
  bottomText,
  title,
  paddingRight,
}) => {
  const { width } = useWindowResize();

  return (
    <div className={styles.container}>
      <div
        className={addClass(
          styles.wrapper,
          paddingRight ? styles.paddingRight : ""
        )}
      >
        {width > 1024 ? (
          <Image noMargin src={desktopBanner} alt="Betfair font banner" />
        ) : null}
        {width <= 1024 ? (
          <Image noMargin src={mobileBanner} alt="Betfair font banner" />
        ) : null}
        <div className={styles.bottomContent}>
          {title && <Heading smallLineHeight text={title} />}
          <div className={styles.bottomText}>
            {bottomText.map((text, i) => (
              <Paragraph
                key={`${i} - ${text}`}
                noMargin
                className={styles.bottomText}
              >
                <p dangerouslySetInnerHTML={{ __html: text }} />
              </Paragraph>
            ))}
          </div>
        </div>
      </div>
      {asideText ? (
        <div className={styles.aside}>
          {asideText.map((text, i) => (
            <Paragraph noMargin key={`${i} - ${text}`}>
              {text}
            </Paragraph>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default BannerTextSection;
