import React from "react";
import ImageAndInfo from "shared/components/ImageAndInfo/ImageAndInfo";

import styles from "./ImageAndInfoSection.module.scss";

const ImageAndInfoSection = ({blackLogo,yellowLogo}) => {
  return (
    <div className={styles.container}>
      <ImageAndInfo
        src={yellowLogo}
        alt="betfair yellow logo on black background"
        title="Betfair Yellow"
        items={[
          {
            text: "RGB: 255 184 12",
          },
          {
            text: "Hex: #FFB80C",
          },
          {
            text: "CMYK: 0 31 87 0",
          },
          {
            text: "Pantone®: 136 C",
          },
        ]}
      />
      <ImageAndInfo
        src={blackLogo}
        alt="betfair black logo on yellow background"
        textColor="#ffb80c"
        background="#000000"
        title="Betfair Black"
        items={[
          {
            text: "RGB: 0 0 0",
          },
          {
            text: "Hex: #000000",
          },
          {
            text: "CMYK: 0 0 0 100",
          },
          {
            text: "Pantone®: 426 C",
          },
        ]}
      />
    </div>
  );
};

export default ImageAndInfoSection;
