import React, { createContext, useReducer, useContext } from 'react';

const initialState = { currentlyPlayingMediaUrl: '', setCurrentlyPlayingMediaUrl: () => { } };

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'SET_CURRENTLY_PLAYING_MEDIA_URL':
            return {
                ...state, currentlyPlayingMediaUrl: payload
            }

        default:
            return state
    }
}

const CurrentlyPlayingMediaContext = createContext(initialState);

const CurrentlyPlayingMediaProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setUrl = (url) => {
        dispatch({
            type: 'SET_CURRENTLY_PLAYING_MEDIA_URL',
            payload: url
        })
    }

    return (
        <CurrentlyPlayingMediaContext.Provider value={{ currentlyPlayingMediaUrl: state.currentlyPlayingMediaUrl, setCurrentlyPlayingMediaUrl: setUrl }}>{children}</CurrentlyPlayingMediaContext.Provider>
    )
};

const useCurrentlyPlayingMedia = () => {
    const context = useContext(CurrentlyPlayingMediaContext);
    return context;
}

export { CurrentlyPlayingMediaProvider, useCurrentlyPlayingMedia };

