import React, { Fragment } from "react";

import PropTypes from "prop-types";

import Image from "../Image/Image";
import Icon from "../Icon/Icon";

import Paragraph from "shared/components/Paragraphs/Paragraph";
import classNames from "classnames/bind";
import styles from "./Gallery.module.scss";
import { addClass } from "utils/addClass";
let cx = classNames.bind(styles);

const Gallery = ({
  images,
  bigImagesOnly,
  rowOf3,
  rowOf4,
  bgColorBlack,
  bgColorWhite,
  rowOf7,
}) => {
  const galleryClasses = cx({
    gallery: true,
    bigImagesOnly: bigImagesOnly,
    rowOf3: rowOf3,
    rowOf4: rowOf4,
    rowOf7: rowOf7,
    bgColorBlack: bgColorBlack,
    bgColorWhite: bgColorWhite,
  });
  return (
    <div className={galleryClasses}>
      {images.map((image, index) => {
        return (
          <Fragment key={`${image.src}-${index}`}>
            {image.downloadUrl && (
              <div className={styles.wrapper}>
                <a
                  href={image.downloadUrl}
                  className={addClass(
                    styles.link,
                    image.dos ? styles.dos : "",
                    image.donts ? styles.donts : "",
                    bgColorBlack ? styles.bgColorBlack : "",
                    bgColorWhite ? styles.bgColorWhite : ""
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="download" className={styles.icon} />
                  <Image
                    fullWidth
                    src={image.src}
                    alt={image.alt}
                    noMargin
                    contain
                  />
                </a>
                {image.text ? (
                  <Paragraph>
                    <p dangerouslySetInnerHTML={{ __html: image.text }}></p>
                  </Paragraph>
                ) : null}
              </div>
            )}
            {!image.downloadUrl && (
              <div
                className={addClass(
                  styles.imgWrapper,
                  image.dos ? styles.dos : "",
                  image.donts ? styles.donts : "",
                  bgColorBlack ? styles.bgColorBlack : "",
                  bgColorWhite ? styles.bgColorWhite : ""
                )}
              >
                <Image fullWidth src={image.src} alt={image.alt} noMargin />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

Gallery.propTypes = {
  bigImagesOnly: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ),
  downloadUrl: PropTypes.string,
};

export default Gallery;
