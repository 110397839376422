import React from "react";
import { addClass } from "utils/addClass";

import styles from "./LogosSection.module.scss";

const LogosSection = ({ children, grid, flex, paddingBottom }) => {
  return (
    <div
      className={addClass(
        styles.container,
        grid === "twoCol" ? styles.twoCol : "",
        grid === "threeCol" ? styles.threeCol : "",
        grid === "fourCol" ? styles.fourCol : "",
        flex ? styles.flex : "",
        paddingBottom ? styles.paddingBottom : ""
      )}
    >
      {children.map((child) => child)}
    </div>
  );
};

export default LogosSection;
