import React from "react";
import Image from "shared/components/Image/Image";

import team from "shared/assets/gameplan/playasateam.svg";
import win from "shared/assets/gameplan/playtowin.svg";
import fair from "shared/assets/gameplan/playfair.svg";

import styles from "./Gameplan.module.scss";

const Gameplan = () => {
  return (
    <div className={styles.container}>
      <Image
        noMargin
        className={styles.img}
        src={team}
        alt="We play as a team image"
      />
      <Image
        noMargin
        className={styles.img}
        src={win}
        alt="We play to win image"
      />
      <Image
        noMargin
        className={styles.img}
        src={fair}
        alt="We play fair image"
      />
    </div>
  );
};

export default Gameplan;
