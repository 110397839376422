import React, { createContext, useReducer, useContext } from 'react';

const initialState = { currentlyActiveSectionId: window.location.hash ? window.location.hash.substr(1) : 'logos', setCurrentlyActiveSectionId: () => { } };

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'SET_CURRENTLY_ACTIVE_SECTION_ID':
            return {
                ...state, currentlyActiveSectionId: payload
            }

        default:
            return state
    }
}

const CurrentlyActiveSectionContext = createContext(initialState);

const CurrentlyActiveSectionProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setId = (id) => {
        dispatch({
            type: 'SET_CURRENTLY_ACTIVE_SECTION_ID',
            payload: id
        })
    }

    return (
        <CurrentlyActiveSectionContext.Provider value={{ currentlyActiveSectionId: state.currentlyActiveSectionId, setCurrentlyActiveSectionId: setId }}>{children}</CurrentlyActiveSectionContext.Provider>
    )
};

const useCurrentlyActiveSection = () => {
    const context = useContext(CurrentlyActiveSectionContext);
    return context;
}

export { CurrentlyActiveSectionProvider, useCurrentlyActiveSection };

