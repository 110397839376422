import React from "react";

import { addClass } from "utils/addClass";
import PropTypes from "prop-types";

import styles from "./Subheading.module.scss";

const Subheading = ({ text, marginL, marginXL, marginXXL }) => {
  return (
    <h4
      className={addClass(
        styles.subheading,
        marginL ? styles.marginL : "",
        marginXL ? styles.marginXL : "",
        marginXXL ? styles.marginXXL : ""
      )}
    >
      {text}
    </h4>
  );
};

Subheading.propTypes = {
  text: PropTypes.string,
};

export default Subheading;
