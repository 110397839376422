import React from "react";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import Image from "shared/components/Image/Image";

import styles from "./BrandLogos.module.scss";

const BrandLogos = ({ yellowLogo, blackLogo }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Image
          className={styles.img}
          src={yellowLogo}
          alt="betfair yellow logo on black background"
          smallMargin
        />
        <DownloadButton
          noMargin
          title="Download Yellow Logo"
          href={
            "./downloads/1_Logos for Microsite/bf-yellow-logo-black-bg-v2 1.svg"
          }
        />
      </div>
      <div className={styles.wrapper}>
        <Image
          className={styles.img}
          src={blackLogo}
          alt="betfair yellow logo on black background"
          smallMargin
        />
        <DownloadButton
          noMargin
          title="Download Black Logo"
          href={
            "./downloads/1_Logos for Microsite/bf-yellow-logo-black-bg-v2 2.svg"
          }
        />
      </div>
    </div>
  );
};

export default BrandLogos;
