import React, { Fragment } from "react";

import Heading from "shared/components/Heading/Heading";
import Paragraph from "shared/components/Paragraphs/Paragraph";
import DownloadButton from "shared/components/DownloadButton/DownloadButton";
import Image from "shared/components/Image/Image";

import betfairFightsBack from "shared/assets/strategy-page/betfair-fights-back.jpg";
import betfairSaferBrandGuide from "shared/assets/strategy-page/betfair-safer-brand-guide.jpg";
import betfairStrategy from "shared/assets/strategy-page/betfair-strategy.jpg";

const StrategyTemplate = () => {
  return (
    <Fragment>
      <Heading smallLineHeight text="Strategy" />

      <Paragraph>
        <p>
          Here you'll find any decks that outline key strategic areas of focus
          in the business.
        </p>
      </Paragraph>

      <Heading smallLineHeight text="Betfair Fights Back" />
      <Image src={betfairFightsBack} alt="betfair Fights Back" />
      <DownloadButton
        title="Download Betfair Fights Back"
        href="./downloads/9_Strategy/Betfair Fights Back 050221.pdf"
      />

      <Heading smallLineHeight text="Strategy 2022" />
      <Image src={betfairStrategy} alt="betfair Strategy" />
      <DownloadButton
        title="Download Betfair Brand Strategy"
        href="./downloads/9_Strategy/BRAND HOUSE.pdf"
      />

      <Heading smallLineHeight text="Betfair Safer Gambling Brand Guide" />
      <Image src={betfairSaferBrandGuide} alt="betfair Safer Brand Guide" />
      <DownloadButton
        title="Download Betfair Safer Gambling Brand Guide"
        href="./downloads/9_Strategy/Betfair_SaferGambling_Brand_guide_Final_23.12.21.pdf"
      />
    </Fragment>
  );
};

export default StrategyTemplate;
